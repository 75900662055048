import React from 'react';
import { useUpdateInfinityData } from '@lobox/utils';
import UnitSectionItem from '@shared/components/Organism/UnitSection/UnitSection.item';
import {
  FollowersItem,
  PendingRequestItem,
} from '@shared/components/molecules';
import useCardMenuBuilder from '@shared/hooks/useCardMenuBuilder';
import { followStatus as fS } from '@shared/constants/enums';
import type {
  QueryKeyType,
  PeopleFollowResponseType,
  PeopleType,
} from '@lobox/utils';
import type { AvatarProps } from '@lobox/uikit/Avatar';

interface PeopleCardProps {
  people: PeopleType;
  queryKey: QueryKeyType;
  onSelectHandler?: (item: any, routeName?: string) => void;
  onSuccess?: (item: PeopleType) => void;
  avatarProps?: AvatarProps;
}

const PeopleCard: React.FC<PeopleCardProps> = ({
  people,
  queryKey,
  onSelectHandler,
  onSuccess,
  avatarProps,
}): JSX.Element => {
  const menuBuilder = useCardMenuBuilder(false);
  const { replace, remove } = useUpdateInfinityData(queryKey);
  const success =
    (item: PeopleType, follow: boolean) =>
    ({ followStatus }: PeopleFollowResponseType) => {
      const newItem = { ...item, follow, followStatus };
      replace(newItem);
      onSuccess?.(newItem);
    };
  const handleBlock = (item: PeopleType, isBlocked: boolean) => () => {
    const newItem = { ...item, isBlocked };
    replace(newItem);
  };
  const handleRemove = (id: string) => () => {
    remove(id);
  };

  const menu = menuBuilder({
    ...people,
    unfollowSuccess: success(people, false),
    block: handleBlock(people, true),
  });

  return (
    <UnitSectionItem
      avatarProps={avatarProps}
      item={{
        id: people.id,
        image: people.croppedImageUrl,
        title: `${people.name} ${people.surname}`,
        subtitle: `@${people.username}`,
        to: !onSelectHandler ? `/${people.username}` : undefined,
        onClick: onSelectHandler,
        postsCounter: people.postsCounter,
        croppedHeaderImageLink: people.croppedHeaderImageLink,
        locationTitle: people.locationTitle,
        description: people.occupationName,
        followers: people.followersCounter,
        following: people.followingsCounter,
        actions:
          people.followStatus === fS.PENDING ? (
            <PendingRequestItem
              object={people}
              onSuccess={success(people, false)}
            />
          ) : (
            <FollowersItem
              object={{
                id: people.id,
                name: `${people.name} ${people.surname}`,
                username: people.username,
                croppedImageUrl: people.croppedImageUrl,
                fullName: `${people.name} ${people.surname}`,
                isFollowed: people.follow,
                back: people?.back,
              }}
              onSuccess={success(people, !people.follow)}
            />
          ),
        moreList: menu,
        isBlocked: people.isBlocked,
        undoBlock: handleBlock(people, false),
        remove: handleRemove(people.id),
        username: people.username as string,
        isPrivateAccount:
          people.privateProfile &&
          (!people.follow ||
            (people.follow && people.followStatus !== 'ACCEPTED')),
      }}
    />
  );
};

export default PeopleCard;
