import classes from './ViewPortList.module.scss';

import React, { forwardRef } from 'react';
import { Virtuoso } from 'react-virtuoso';
import type { VirtuosoProps } from 'react-virtuoso';
import cnj from '../utils/cnj';

interface Props extends VirtuosoProps<any, any> {
  useRelativeScroller?: boolean;
}

const RelativeScroller = React.forwardRef(
  (
    { className, ...rest }: React.HTMLProps<HTMLDivElement>,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div className={cnj(className, classes.scroller)} {...rest} ref={ref} />
    );
  }
);

const ViewPortListRender = (
  { components, useRelativeScroller = false, ...rest }: Props,
  ref: any
): JSX.Element => (
  <Virtuoso
    {...rest}
    components={{
      Scroller: useRelativeScroller ? RelativeScroller : undefined,
      ...components,
    }}
    ref={ref}
  />
);

export default forwardRef(ViewPortListRender);
