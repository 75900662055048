import React from 'react';
import {
  collectionToObjectByKey,
  db,
  useTranslation,
  useUpdateInfinityData,
} from '@lobox/utils';
import UnitSectionItem from '@shared/components/Organism/UnitSection/UnitSection.item';
import { FollowersItem } from '@shared/components/molecules';
import useCardMenuBuilder from '@shared/hooks/useCardMenuBuilder';
import type { PageType, QueryKeyType } from '@lobox/utils';

const pagesCategories = collectionToObjectByKey(db.CATEGORY_TYPES);

interface PageCardProps {
  page: PageType;
  queryKey: QueryKeyType;
  onSelectHandler?: (item: any, routeName?: string) => void;
  onSuccess?: (item: PageType) => void;
}

const PageCard: React.FC<PageCardProps> = ({
  page,
  queryKey,
  onSelectHandler,
  onSuccess,
}): JSX.Element => {
  const menuBuilder = useCardMenuBuilder(true);
  const { t } = useTranslation();
  const { replace, remove } = useUpdateInfinityData(queryKey);

  const success = (item: PageType, follow: boolean) => () => {
    const newItem = {
      ...item,
      follow,
      networkModel: {
        ...item.networkModel,
        follow,
      },
    };
    replace(newItem);
    onSuccess?.(newItem);
  };

  const handleBlock = (item: PageType, isBlocked: boolean) => () => {
    const newItem = { ...item, isBlocked };
    replace(newItem);
  };
  const handleRemove = (id: string) => () => {
    remove(id);
  };
  const menu = menuBuilder({
    ...page,
    unfollowSuccess: success(page, false),
    block: handleBlock(page, true),
  });

  return (
    <UnitSectionItem
      avatarProps={{ isCompany: true }}
      item={{
        id: page.id,
        image: page.croppedImageUrl,
        title: page.title,
        subtitle: `@${page.username}`,
        to: !onSelectHandler ? `/${page.username}` : undefined,
        onClick: onSelectHandler,
        postsCounter: page.postsCounter,
        croppedHeaderImageLink: page.croppedHeaderImageLink,
        locationTitle: page.locationTitle,
        description: t(pagesCategories[page.category]?.label),
        followers: page.followersCounter,
        following: page.followingsCounter,
        actions: (
          <FollowersItem
            object={{
              isPage: true,
              id: page.id,
              name: page.title,
              username: page.username as string,
              croppedImageUrl: page.croppedImageUrl,
              fullName: page.title,
              isFollowed: page.follow,
              back: page.back,
            }}
            onSuccess={() => {
              console.log('PP', page);
              success(page, !page.follow)();
            }}
          />
        ),
        moreList: menu,
        isBlocked: page.isBlocked,
        undoBlock: handleBlock(page, false),
        remove: handleRemove(page.id),
        username: page.username as string,
      }}
    />
  );
};

export default PageCard;
