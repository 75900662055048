import React from 'react';
import ReactInfiniteScrollComponent from 'react-infinite-scroller';
// import type {InfiniteScrollProps} from 'react-infinite-scroller';

type InfiniteScrollProps = any;

type IInfiniteScrollProps = InfiniteScrollProps & {
  children: React.ReactElement;
};

const ReactInfiniteScroll: React.FC<IInfiniteScrollProps> = ({
  children,
  ...res
}) => (
  <ReactInfiniteScrollComponent {...res}>
    {children}
  </ReactInfiniteScrollComponent>
);

export default ReactInfiniteScroll;
