import React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

const CreateHighlight: React.FC = (props: any) => {
  const { isDark } = useTheme();
  return isDark ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={70}
      height={65}
      fill="none"
      {...props}
    >
      <circle cx={41.5} cy={28.5} r={28.5} fill="#393939" />
      <path
        fill="#3F73E3"
        d="M41.132 64.25H7.868C6.836 64.25 6 63.223 6 61.956V36.75h37v25.207c0 1.266-.836 2.293-1.868 2.293Z"
      />
      <path
        fill="#3F73E3"
        d="M28.91 52.578c-.225.599-.88.943-1.49.66l-2.475-1.155a1.047 1.047 0 0 0-.89 0l-2.477 1.154c-.609.284-1.264-.06-1.488-.659l-.077.884c-.079.919.781 1.595 1.565 1.229l2.476-1.154c.285-.133.607-.133.89 0l2.477 1.154c.784.366 1.644-.31 1.565-1.23l-.077-.883Z"
      />
      <path
        fill="#64A2FF"
        d="M41.132 28.91H7.868C6.836 28.91 6 29.814 6 30.929V36.2h37v-5.272c0-1.115-.836-2.02-1.868-2.02Z"
      />
      <path fill="#83B4FF" d="M6 34.747h37V36.2H6v-1.454Z" />
      <path
        fill="#485363"
        d="M13.053 25.588c.816 0 1.477.716 1.477 1.598v4.687c0 .883-.661 1.598-1.477 1.598-.817 0-1.478-.715-1.478-1.598v-4.687c0-.882.661-1.598 1.478-1.598ZM35.948 25.588c-.817 0-1.478.716-1.478 1.598v4.687c0 .883.661 1.598 1.478 1.598.816 0 1.477-.715 1.477-1.598v-4.687c0-.882-.661-1.598-1.477-1.598Z"
      />
      <path
        fill="#072252"
        d="M13.053 30.767c-.817 0-1.478-.715-1.478-1.598v1.454c0 .883.661 1.598 1.478 1.598.816 0 1.477-.715 1.477-1.598V29.17c0 .883-.661 1.598-1.477 1.598ZM35.948 30.767c-.817 0-1.478-.715-1.478-1.598v1.454c0 .883.661 1.598 1.478 1.598.816 0 1.477-.715 1.477-1.598V29.17c0 .883-.661 1.598-1.477 1.598Z"
      />
      <path
        fill="#83B4FF"
        d="M10.813 41.212h-.629a.628.628 0 0 1 0-1.257h.629a.628.628 0 0 1 0 1.257ZM13.954 41.212h-.628a.628.628 0 0 1 0-1.257h.628a.628.628 0 0 1 0 1.257ZM17.096 41.212h-.628a.628.628 0 0 1 0-1.257h.628a.628.628 0 0 1 0 1.257ZM20.238 41.212h-.628a.628.628 0 0 1 0-1.257h.628a.628.628 0 0 1 0 1.257ZM23.38 41.212h-.629a.628.628 0 0 1 0-1.257h.629a.628.628 0 0 1 0 1.257ZM26.521 41.212h-.628a.628.628 0 0 1 0-1.257h.628a.628.628 0 0 1 0 1.257ZM29.663 41.212h-.628a.628.628 0 0 1 0-1.257h.628a.628.628 0 0 1 0 1.257ZM32.805 41.212h-.628a.628.628 0 0 1 0-1.257h.628a.628.628 0 1 1 0 1.257ZM35.946 41.212h-.628a.628.628 0 0 1 0-1.257h.628a.628.628 0 0 1 0 1.257ZM39.088 41.212h-.628a.628.628 0 0 1 0-1.257h.628a.628.628 0 0 1 0 1.257ZM29.663 60.062H16.468a.628.628 0 0 1 0-1.256h13.195a.628.628 0 0 1 0 1.256ZM33.433 60.062h-1.256a.628.628 0 1 1 0-1.256h1.256a.628.628 0 0 1 0 1.256Z"
      />
      <path
        fill="#fff"
        d="m19.215 52.678 2.494 2.486 9.41-11.587a1.302 1.302 0 0 1 2.175.237v.003c.217.43.175.946-.108 1.337l-9.94 13.76a1.257 1.257 0 0 1-1.953.107l-4.022-4.45a1.294 1.294 0 0 1-.197-1.446l.054-.106a1.303 1.303 0 0 1 2.087-.34Z"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={70}
      height={65}
      fill="none"
      {...props}
    >
      <circle cx={41.5} cy={28.5} r={28.5} fill="#5384EE" fillOpacity={0.1} />
      <path
        fill="#3F73E3"
        d="M41.132 64.25H7.868C6.836 64.25 6 63.223 6 61.956V36.75h37v25.207c0 1.266-.836 2.293-1.868 2.293Z"
      />
      <path
        fill="#3F73E3"
        d="M28.91 52.578c-.225.599-.88.943-1.49.66l-2.475-1.155a1.047 1.047 0 0 0-.89 0l-2.477 1.154c-.609.284-1.264-.06-1.488-.659l-.077.884c-.079.919.781 1.595 1.565 1.229l2.476-1.154c.285-.133.607-.133.89 0l2.477 1.154c.784.366 1.644-.31 1.565-1.23l-.077-.883Z"
      />
      <path
        fill="#64A2FF"
        d="M41.132 28.91H7.868C6.836 28.91 6 29.814 6 30.929V36.2h37v-5.272c0-1.115-.836-2.02-1.868-2.02Z"
      />
      <path fill="#83B4FF" d="M6 34.747h37V36.2H6v-1.454Z" />
      <path
        fill="#485363"
        d="M13.053 25.588c.816 0 1.477.716 1.477 1.598v4.687c0 .883-.661 1.598-1.477 1.598-.817 0-1.478-.715-1.478-1.598v-4.687c0-.882.661-1.598 1.478-1.598ZM35.948 25.588c-.817 0-1.478.716-1.478 1.598v4.687c0 .883.661 1.598 1.478 1.598.816 0 1.477-.715 1.477-1.598v-4.687c0-.882-.661-1.598-1.477-1.598Z"
      />
      <path
        fill="#072252"
        d="M13.053 30.767c-.817 0-1.478-.715-1.478-1.598v1.454c0 .883.661 1.598 1.478 1.598.816 0 1.477-.715 1.477-1.598V29.17c0 .883-.661 1.598-1.477 1.598ZM35.948 30.767c-.817 0-1.478-.715-1.478-1.598v1.454c0 .883.661 1.598 1.478 1.598.816 0 1.477-.715 1.477-1.598V29.17c0 .883-.661 1.598-1.477 1.598Z"
      />
      <path
        fill="#83B4FF"
        d="M10.813 41.212h-.629a.628.628 0 0 1 0-1.257h.629a.628.628 0 0 1 0 1.257ZM13.954 41.212h-.628a.628.628 0 0 1 0-1.257h.628a.628.628 0 0 1 0 1.257ZM17.096 41.212h-.628a.628.628 0 0 1 0-1.257h.628a.628.628 0 0 1 0 1.257ZM20.238 41.212h-.628a.628.628 0 0 1 0-1.257h.628a.628.628 0 0 1 0 1.257ZM23.38 41.212h-.629a.628.628 0 0 1 0-1.257h.629a.628.628 0 0 1 0 1.257ZM26.521 41.212h-.628a.628.628 0 0 1 0-1.257h.628a.628.628 0 0 1 0 1.257ZM29.663 41.212h-.628a.628.628 0 0 1 0-1.257h.628a.628.628 0 0 1 0 1.257ZM32.805 41.212h-.628a.628.628 0 0 1 0-1.257h.628a.628.628 0 1 1 0 1.257ZM35.946 41.212h-.628a.628.628 0 0 1 0-1.257h.628a.628.628 0 0 1 0 1.257ZM39.088 41.212h-.628a.628.628 0 0 1 0-1.257h.628a.628.628 0 0 1 0 1.257ZM29.663 60.062H16.468a.628.628 0 0 1 0-1.256h13.195a.628.628 0 0 1 0 1.256ZM33.433 60.062h-1.256a.628.628 0 1 1 0-1.256h1.256a.628.628 0 0 1 0 1.256Z"
      />
      <path
        fill="#fff"
        d="m19.215 52.678 2.494 2.486 9.41-11.587a1.302 1.302 0 0 1 2.175.237v.003c.217.43.175.946-.108 1.337l-9.94 13.76a1.257 1.257 0 0 1-1.953.107l-4.022-4.45a1.294 1.294 0 0 1-.197-1.446l.054-.106a1.303 1.303 0 0 1 2.087-.34Z"
      />
    </svg>
  );
};
export default CreateHighlight;
