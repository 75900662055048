import classes from './SocialConnectionsModal.component.module.scss';

import React, { useState } from 'react';
import {
  getFollowers,
  getFollowings,
  getMutual,
  getWorkingAtCompany,
  QueryKeys,
  routeNames,
  useGlobalDispatch,
  useGlobalState,
  useTranslation,
  useUpdateInfinityData,
  useUpdateQueryData,
} from '@lobox/utils';
import {
  FollowButton,
  ModalDialog,
  ModalHeaderSimple,
} from '@shared/components/molecules';
import Tabs from '@shared/components/containers/Tabs';
import Flex from '@lobox/uikit/Flex';
import TextLink from '@lobox/uikit/Link/TextLink';
import Typography from '@lobox/uikit/Typography';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import { followStatus, SocialConnectionsTabs } from '@shared/constants/enums';
import List from './SocialConnectionsModal.list';
import SocialConnectionsRequestList from './SocialConnectionsModal.request';

const SocialConnectionsModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useGlobalDispatch();
  const objectNetworkModal = useGlobalState('objectNetworkModal');
  const [visibleRequestList, setVisibleRequestList] = useState(false);
  const { getAppObjectPropValue, reFetchAppObject, authUser } =
    useGetAppObject();
  const defaultActiveTab = objectNetworkModal?.currentTab;
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const objectId = objectNetworkModal?.objectId;
  const title = objectNetworkModal?.objectTitle;
  const username = objectNetworkModal?.objectUsername;
  const isLoggedInUser = authUser?.id === objectId;
  const { refetch } = useUpdateQueryData([QueryKeys.objectDetail, username]);
  const { refetch: refetchFollowers } = useUpdateInfinityData([
    QueryKeys.followers,
    ` ${objectId}`,
  ]);
  const authObjectId = getAppObjectPropValue({
    userKey: 'id',
    pageKey: 'id',
  });

  const onClickRequestButton = () => setVisibleRequestList(true);
  const closeHandler = () => {
    dispatch({ type: 'CLOSE_OBJECT_NETWORK_MODAL' });
  };
  const onSuccessFollowHandler = () => {
    refetch();
    refetchFollowers();
    reFetchAppObject();
  };

  const tabs = [
    {
      path: SocialConnectionsTabs.followers,
      title: t('followers_cap'),
      content: () => (
        <List
          apiFunc={getFollowers}
          activeTab={activeTab}
          queryKey={QueryKeys.followers}
          onClickRequestButton={onClickRequestButton}
          isAuthUserVisible={
            objectNetworkModal?.network?.follow &&
            (objectNetworkModal?.network?.followStatus ===
              followStatus.ACCEPTED ||
              objectNetworkModal.isPage)
          }
          emptyCaption={t('no_followers')}
          placeholder={t('search')}
          emptyMessage={
            isLoggedInUser ? (
              <Flex className={classes.messageWrap}>
                <Typography>{t('y_d_n_h_a_f')}</Typography>
                <TextLink
                  className={classes.messageWrap}
                  to={`${routeNames.invite}`}
                  typographyProps={{
                    ml: 4,
                    mr: 4,
                    color: 'brand',
                    onClick: closeHandler,
                  }}
                >
                  {t('invite')}
                </TextLink>
                <Typography>{t('y_c_t_c_inter')}</Typography>
              </Flex>
            ) : (
              <Flex>
                <Typography textAlign="center">{`${title} ${t(
                  'doesnt_h_a_f_f_t_keep'
                )}`}</Typography>
                {!!username && (
                  <FollowButton
                    back={objectNetworkModal?.network?.back}
                    onSuccess={onSuccessFollowHandler}
                    schema="primary-blue"
                    className={classes.followButton}
                    object={{
                      id: objectId,
                      isPage: objectNetworkModal?.isPage,
                      username,
                    }}
                  />
                )}
              </Flex>
            )
          }
        />
      ),
    },
    {
      path: SocialConnectionsTabs.following,
      title: t('following_cap'),
      content: () => (
        <List
          activeTab={activeTab}
          apiFunc={(args: any) =>
            getFollowings({
              ...args,
              isAuthObjectFollowing: authObjectId === objectId,
            })
          }
          placeholder={t('search')}
          queryKey={QueryKeys.followings}
          onClickRequestButton={onClickRequestButton}
          isAuthUserVisible={objectNetworkModal?.network?.back}
          emptyCaption={t('no_following')}
          emptyMessage={
            isLoggedInUser
              ? t('y_a_n_f_any')
              : `${title} ${t('isnt_f_an_y_ch_lat')}`
          }
        />
      ),
    },
    !isLoggedInUser && {
      path: SocialConnectionsTabs.mutuals,
      title: t('mutuals'),
      content: () => (
        <List
          activeTab={activeTab}
          apiFunc={getMutual}
          queryKey={QueryKeys.pageMutual}
          isAuthUserVisible={false}
          emptyCaption={t('no_mutuals')}
          placeholder={t('search')}
          emptyMessage={`${title} ${t('doesnt_h_a_mut_con')}`}
        />
      ),
    },
    objectNetworkModal?.isPage && {
      path: SocialConnectionsTabs.worksHere,
      title: t('works_here'),
      content: () => (
        <List
          activeTab={activeTab}
          apiFunc={getWorkingAtCompany}
          queryKey={QueryKeys.getWorkingAtCompany}
          isAuthUserVisible={false}
          emptyCaption={t('no_employees')}
          placeholder={t('search')}
          emptyMessage={t('no_employees_caption')}
        />
      ),
    },
  ].filter(Boolean);

  return (
    <ModalDialog
      modalClassName={classes.modalClassName}
      modalDialogClassName={classes.modalDialogClassName}
      onBack={closeHandler}
      onClose={closeHandler}
      isOpen
    >
      {visibleRequestList ? (
        <>
          <ModalHeaderSimple
            backButtonProps={{ onClick: () => setVisibleRequestList(false) }}
            title={t('requests')}
            hideBack={false}
            closeButtonProps={{
              onClick: () => setVisibleRequestList(false),
            }}
            visibleHeaderDivider
          />
          <SocialConnectionsRequestList activeTab={activeTab} />
        </>
      ) : (
        <>
          <ModalHeaderSimple
            title={title}
            titleProps={{
              isWordWrap: true,
              isTruncated: true,
              lineNumber: 1,
              className: classes.title,
            }}
            backButtonProps={{ onClick: closeHandler }}
            closeButtonProps={{ onClick: closeHandler }}
            visibleHeaderDivider
          />
          <Tabs
            onChangeTab={setActiveTab}
            styles={{
              tabsWrap: classes.tabsWrap,
              tabsRoot: classes.tabsRoot,
              content: classes.content,
            }}
            activePath={defaultActiveTab}
            tabs={tabs}
          />
        </>
      )}
    </ModalDialog>
  );
};

export default SocialConnectionsModal;
