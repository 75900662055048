import React, { Fragment } from 'react';
import ReactInfiniteScroll from '@lobox/uikit/ReactInfiniteScroll';

interface IInfiniteScrollProps {
  data: Array<any>;
  renderItem: (item?: any, index?: any) => React.ReactElement;
  onEndReached?: () => void;
  hasMore?: boolean;
  loadingComponent: React.ReactElement;
  keyField?: string;
}
const InfiniteScroll: React.FC<IInfiniteScrollProps> = ({
  data,
  renderItem,
  onEndReached,
  hasMore,
  loadingComponent,
  keyField = 'id',
}) => (
  <ReactInfiniteScroll
    initialLoad={false}
    pageStart={0}
    loadMore={() => {
      onEndReached?.();
    }}
    hasMore={hasMore}
    useWindow={false}
    // @ts-ignore
    loader={loadingComponent}
  >
    {data.map((item: any, index: number) => (
      <Fragment key={item?.[keyField]}>{renderItem(item, index)}</Fragment>
    ))}
  </ReactInfiniteScroll>
);

export default InfiniteScroll;
