import classes from './SocialConnectionsModal.actions.module.scss';

import React from 'react';
import Button from '@lobox/uikit/Button';
import Icon from '@lobox/uikit/Icon';
import useMedia from '@lobox/uikit/utils/useMedia';
import {
  preventClickHandler,
  QueryKeys,
  useTranslation,
  useUpdateQueryData,
} from '@lobox/utils';
import useAcceptFollow from '@shared/hooks/api-hook/useAcceptFollow';
import useDeclineFollow from '@shared/hooks/api-hook/useDeclineFollow';
import callbacksExecutor from '@shared/utils/callbacksExecutor';
import { useQueryClient } from '@tanstack/react-query';

export interface SocialConnectionsActionsProps {
  id?: string;
  username: string;
  onSuccessAccept?: (data: any, variables?: void, context?: unknown) => void;
  onSuccessDecline?: (data: any, variables?: void, context?: unknown) => void;
}

const SocialConnectionsActions: React.FC<SocialConnectionsActionsProps> = ({
  id,
  username,
  onSuccessAccept,
  onSuccessDecline,
}) => {
  const { t } = useTranslation();
  const { isMoreThanTablet } = useMedia();
  const { acceptCall, isLoading: isLoadingAccept } = useAcceptFollow();
  const { declineCall, isLoading: isLoadingDecline } = useDeclineFollow();
  const { replace } = useUpdateQueryData([QueryKeys.objectDetail, username]);
  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData([QueryKeys.objectDetail, username]);
  const replaceDeclineUserData = () => {
    if (userData) {
      replace({
        network: {
          ...userData.network,
          follow: false,
          followStatus: null,
          backStatus: null,
        },
      });
    }
  };

  const replaceAcceptUserData = (res: any) => {
    if (userData) {
      replace({
        network: {
          ...userData.network,
          follow: true,
          followStatus: res?.followStatus,
          backStatus: null,
        },
      });
    }
  };

  return (
    <>
      <Button
        isLoading={isLoadingAccept}
        labelFont="700"
        onClick={(e: any) => {
          preventClickHandler(e);

          acceptCall(id, {
            onSuccess: callbacksExecutor(
              onSuccessAccept,
              replaceAcceptUserData
            ),
          });
        }}
        className={classes.followBtn}
        label={isMoreThanTablet ? t('accept') : undefined}
        leftSvg={
          isMoreThanTablet ? undefined : (
            <Icon name="check" color="white" size={18} />
          )
        }
      />
      <Button
        isLoading={isLoadingDecline}
        className={classes.followBtn}
        labelFont="700"
        schema="ghost"
        onClick={(e: any) => {
          preventClickHandler(e);

          declineCall(id, {
            onSuccess: callbacksExecutor(
              onSuccessDecline,
              replaceDeclineUserData
            ),
          });
        }}
        label={isMoreThanTablet ? t('decline') : undefined}
        leftSvg={
          isMoreThanTablet ? undefined : (
            <Icon name="times" color="graphene" size={18} />
          )
        }
      />
    </>
  );
};

export default SocialConnectionsActions;
