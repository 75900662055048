import classes from './CreateEntityModal.component.module.scss';

import React from 'react';
import Flex from '@lobox/uikit/Flex';
import useMedia from '@lobox/uikit/utils/useMedia';
import {
  useTranslation,
  useGlobalDispatch,
  routeNames,
  schedulesEventValues,
} from '@lobox/utils';
import {
  ModalBody,
  ModalHeaderSimple,
} from '@shared/components/molecules/Modal';
import Create from '@shared/svg/CreateIcon';
import CreateHighlight from '@shared/svg/CreateHighlight';
import Page from '@shared/svg/PageIcon';
import CreateJob from '@shared/svg/CreateJob';
import CreateSchedule from '@shared/svg/CreateSchedule';
import { useScheduleDispatch } from '@/shared/contexts/Schedule/schedule.provider';
import { useHistory } from '@lobox/utils';
import FixedRightSideModal from '@shared/components/molecules/Modal/FixedRightSideModalDialog';
import CreateEntityModalItem from './CreateEntityModal.item';
import { isBusinessApp } from '@shared/utils/getAppEnv';

const CreateEntityModal = (): JSX.Element => {
  const { isMoreThanTablet } = useMedia();
  const { t } = useTranslation();
  const history = useHistory();
  const appDispatch = useGlobalDispatch();
  const scheduleDispatch = useScheduleDispatch();

  const toggleModal = () => {
    appDispatch({ type: 'TOGGLE_GLOBAL_ENTITY_MODAL' });
  };

  const items = [
    {
      key: 'post',
      title: t('post'),
      subTitle: t('share_yr_tho_ph_and_vid'),
      image: <Create />,
      onClick: () =>
        appDispatch({
          type: 'SET_CREATE_POST_MODAL',
          payload: {
            isOpenModal: true,
            currentTab: 'main',
          },
        }),
    },
    // {
    //   key: 'article',
    //   title: t('article'),
    //   subTitle: t('share_yr_ex_and_pass'),
    //   image: <Create />,
    //   onClick: () => {},
    // },
    // {
    //   key: 'news',
    //   title: t('news'),
    //   subTitle: t('share_breaking_news_anc_capt'),
    //   image: <Create />,
    //   onClick: () => {},
    // },
    {
      key: 'highlight',
      title: t('highlight'),
      subTitle: t('showcase_yr_best_mom_lif'),
      image: <CreateHighlight />,
      onClick: () =>
        appDispatch({
          type: 'SET_CREATE_POST_MODAL',
          payload: {
            isOpenModal: true,
            currentTab: 'highlight',
          },
        }),
    },

    !isBusinessApp && {
      key: 'pages',
      title: t('page'),
      subTitle: t('create_pg_f_y_cmp_ins'),
      image: <Page />,
      onClick: () => {
        history.push(`${routeNames.page}${routeNames.pageCreation}`);
      },
    },
    !isBusinessApp && {
      key: 'job',
      title: t('job_cap'),
      subTitle: t('recruit_top_tal_f_yr_pr'),
      image: <CreateJob />,
      onClick: () =>
        appDispatch({
          type: 'TOGGLE_CREATE_JOB_MODAL_IN_USER_PROJECT',
          payload: true,
        }),
    },

    // {
    //   key: 'group',
    //   title: t('group'),
    //   subTitle: t('content_an_engage_with_lik_indiv'),
    //   image: <Create />,
    //   onClick: () => {},
    // },
    {
      key: 'schedule',
      title: t('schedule_cap'),
      subTitle: t('efficiently_manag_yr_tim_even'),
      image: <CreateSchedule />,
      onClick: () => {
        appDispatch({
          type: 'SET_SHOW_RIGHT_PANEL',
          payload: true,
        });
        scheduleDispatch({
          type: 'SET_SCHEDULE_CREATION_MODAL_DATA',
          payload: {
            visibleForm: true,
            schedulesEventType: schedulesEventValues.MEETING,
          },
        });
      },
    },
  ].filter(Boolean);

  return (
    <FixedRightSideModal
      onBack={toggleModal}
      onClose={toggleModal}
      onClickOutside={toggleModal}
    >
      <ModalHeaderSimple title={t('create')} hideBack={isMoreThanTablet} />
      <ModalBody>
        <Flex className={classes.contentWrapper}>
          {items.map(({ key, title, subTitle, image, onClick }: any) => {
            const buttonClick = () => {
              toggleModal();
              onClick();
            };

            return (
              <CreateEntityModalItem
                key={key}
                onClick={buttonClick}
                image={image}
                title={title}
                subTitle={subTitle}
              />
            );
          })}
        </Flex>
      </ModalBody>
    </FixedRightSideModal>
  );
};

export default CreateEntityModal;
