import classes from './CreateEntityModal.item.module.scss';

import React from 'react';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';

interface Props {
  onClick: () => void;
  title: string;
  image: string;
  subTitle: string;
}

const CreateEntityModalItem: React.FC<Props> = ({
  onClick,
  image,
  title,
  subTitle,
}) => {
  return (
    <Flex className={classes.item}>
      <BaseButton className={cnj(classes.rootButton)} onClick={onClick}>
        <Flex className={classes.imageWrap}>{image}</Flex>
        <Typography
          color="smoke_coal"
          size={16}
          textAlign="left"
          font="bold"
          height={20}
          mt={12}
          isTruncated
        >
          {title}
        </Typography>
        <Typography
          className={cnj(classes.width)}
          size={12}
          mt={8}
          color="secondaryDisabledText"
          height={18}
          lineNumber={2}
        >
          {subTitle}
        </Typography>
      </BaseButton>
    </Flex>
  );
};

export default CreateEntityModalItem;
