import classes from './SocialConnectionsModal.emptyState.module.scss';

import React from 'react';
import { EmptyState } from '@shared/components/Organism';
import EmptySearchResultSvg from '@shared/svg/EmptySearchResult';

export interface SocialConnectionsEmptyStateProps {
  caption: string;
  message: string;
}

const SocialConnectionsEmptyState: React.FC<
  SocialConnectionsEmptyStateProps
> = ({ caption, message }): JSX.Element => {
  return (
    <EmptyState
      image={<EmptySearchResultSvg />}
      caption={caption}
      message={message}
      captionProps={{ mt: 0, className: classes.captionClassName }}
      contentClassName={classes.contentClassName}
      imageContainerClassName={classes.imageContainerClassName}
      imageClassName={classes.image}
    />
  );
};

export default SocialConnectionsEmptyState;
