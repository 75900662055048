import classes from './SocialConnectionsModal.skeleton.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Skeleton from '@lobox/uikit/Skeleton';
import { placeholderData } from '@shared/constants/enums';

interface SocialConnectionsSkeletonProps {
  visibleSearchInput?: boolean;
  className?: string;
}

const SocialConnectionsModalSkeleton: React.FC<
  SocialConnectionsSkeletonProps
> = ({ visibleSearchInput = true, className }) => {
  return (
    <Flex className={cnj(classes.objectNetwork, className)}>
      {visibleSearchInput && (
        <Skeleton className={classes.skeleton__1} />
      )}
      {placeholderData('object-network-list', 4).map(({ id }) => (
        <Flex key={id} className={classes.itemWrapper}>
          <Skeleton className={classes.skeleton__2} />
          <Flex>
            <Skeleton className={classes.skeleton__3} />
            <Skeleton className={classes.skeleton__4} />
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};

export default SocialConnectionsModalSkeleton;
