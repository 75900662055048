import classes from './ObjectCard.skeleton.module.scss';

import React from 'react';
// import Flex from '@lobox/uikit/Flex';
// import Box from '@lobox/uikit/Layout/Box';
import Skeleton from '@lobox/uikit/Skeleton';

export interface ObjectCardSkeletonProps {
  className?: string;
  isPage?: boolean;
  myOwnPage?: boolean;
}

const ObjectCardSkeleton: React.FC<ObjectCardSkeletonProps> = (/*{
  className,
  isPage,
  myOwnPage,
}*/) => {
  return <Skeleton className={classes.skeleton} />;
};

export default ObjectCardSkeleton;
