import {
  QueryKeys,
  getPendingRequestsPaginated,
  getFollowRequestPaginated,
  useInfiniteQuery,
} from '@lobox/utils';

const useGetFollowRequest = (isFollower?: boolean, enabled = true) => {
  const pendingRequests = useInfiniteQuery<any>(
    [QueryKeys.getPendingRequests],
    {
      func: getPendingRequestsPaginated,
      size: 10,
    },
    {
      enabled: !isFollower && enabled,
    }
  );

  const followRequest = useInfiniteQuery<any>(
    [QueryKeys.getMyFollowRequests],
    {
      func: getFollowRequestPaginated,
      size: 10,
    },
    {
      enabled: isFollower && enabled,
    }
  );

  return isFollower ? followRequest : pendingRequests;
};

export default useGetFollowRequest;
