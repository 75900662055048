import classes from './InfiniteScrollUnitSection.module.scss';

import React, { useMemo } from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import { useWindowDimensions } from '@lobox/utils';
import UnitSectionSkeleton from '@shared/components/molecules/UnitSectionSkeleton/UnitSectionSkeleton';
import PageCard from '@shared/components/Organism/PageCard';
import PeopleCard from '@shared/components/Organism/PeopleCard';
import UnitSectionItem from '@shared/components/Organism/UnitSection/UnitSection.item';
import type { QueryKeyType } from '@lobox/utils';
import type { AvatarProps } from '@lobox/uikit/Avatar';
import Section from '../../molecules/Section';
import type {
  ObjectCardProps,
  ObjectSectionContainerProps,
} from '../../molecules';
import BaseButton from '@shared/uikit/Button/BaseButton';
import { useObjectClicks } from '@shared/hooks/useObjectClicks';
import ViewPortList from '@shared/uikit/ViewPortList';
import Flex from '@shared/uikit/Flex';

export interface UnitSectionProps {
  className?: string;
  isLoading?: boolean;
  sectionProps?: ObjectSectionContainerProps;
  avatarProps?: AvatarProps;
  list?: Array<ObjectCardProps>;
  emptyState?: React.ReactNode;
  isFetchingNextPage?: boolean;
  fetchNextPage: () => void;
  queryKey: QueryKeyType;
  onSuccess: (item: any) => void;
  showDefaultCard?: boolean;
  hasNextPage: boolean;
  cardClickProps?: Record<any, any>;
}

const InfiniteScrollUnitSection = ({
  className,
  isLoading,
  sectionProps,
  avatarProps: aP,
  list = [],
  emptyState,
  isFetchingNextPage,
  fetchNextPage,
  queryKey,
  onSuccess,
  showDefaultCard,
  hasNextPage,
  cardClickProps,
}: UnitSectionProps): JSX.Element => {
  const { height } = useWindowDimensions();
  let viewPortHeight: string | number = '100%';
  if (height > 0) {
    viewPortHeight = height - 135;
  }

  const { handleTagClick } = useObjectClicks();

  const handleClick = (objectElement: any) => () => {
    handleTagClick(
      objectElement.username,
      objectElement.id,
      aP?.isCompany ? 'pages' : 'people',
      cardClickProps
    );
  };

  const _data = useMemo(
    () =>
      list
        ?.map((item, index) =>
          index % 3 === 0 ? [item, list[index + 1], list[index + 2]] : false
        )
        ?.filter(Boolean),
    [list]
  );

  const renderItem = (objectElement: any) => {
    if (showDefaultCard)
      return (
        <BaseButton
          onClick={handleClick(objectElement)}
          className={cnj(classes.itemClassName)}
        >
          <UnitSectionItem
            item={objectElement}
            avatarProps={aP}
            noLinkOnClick
          />{' '}
        </BaseButton>
      );

    return aP?.isCompany ? (
      <BaseButton
        onClick={handleClick(objectElement)}
        className={cnj(classes.itemClassName)}
      >
        <PageCard
          page={objectElement}
          queryKey={queryKey}
          onSuccess={onSuccess}
        />
      </BaseButton>
    ) : (
      <BaseButton
        onClick={handleClick(objectElement)}
        className={cnj(classes.itemClassName)}
      >
        <PeopleCard
          people={objectElement}
          queryKey={queryKey}
          onSuccess={onSuccess}
        />
      </BaseButton>
    );
  };

  const itemContent = (index: number) => {
    const item1 = _data[index][0];
    const item2 = _data[index][1];
    const item3 = _data[index][2];
    return (
      <Flex className={classes.setOfItems}>
        {!!item1 && renderItem(item1)}
        {!!item2 && renderItem(item2)}
        {!!item3 && renderItem(item3)}
      </Flex>
    );
  };

  const endReached = () => {
    isLoading || isFetchingNextPage || !hasNextPage
      ? undefined
      : fetchNextPage?.();
  };

  const isEmpty = !list?.length && !isLoading;

  return (
    <Section className={cnj(classes.section, className)} {...sectionProps}>
      {isLoading ? (
        <UnitSectionSkeleton totalElements={isFetchingNextPage ? 3 : 6} />
      ) : isEmpty ? (
        emptyState
      ) : (
        <ViewPortList
          totalCount={_data.length}
          itemContent={itemContent}
          className={classes.virtualContainer}
          useRelativeScroller
          endReached={endReached}
        />
      )}
    </Section>
  );
};

export default InfiniteScrollUnitSection;
