import * as React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

function PageIcon(props: React.SVGProps<SVGSVGElement>) {
  const { isDark } = useTheme();

  return isDark ? (
    <svg
      id="Layer_pageIconDark"
      data-name="Layer pageIconDark"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 70 65"
      width={70}
      height={65}
      {...props}
    >
      <defs>
        <style>
          {
            '.cls-page-i-1{fill:#393939;}.cls-page-i-2{fill:#5384ee;}.cls-page-i-3{fill:#3f73e3;}.cls-page-i-4{fill:#072252;}.cls-page-i-5{fill:#ececec;}.cls-page-i-6{fill:#fff;}.cls-page-i-7{fill:#8493a8;}'
          }
        </style>
      </defs>
      <circle className="cls-page-i-1" cx={41.5} cy={28.5} r={28.5} />
      <path className="cls-page-i-2" d="M38.47,25.94l-4,8,4,8h2.78V25.94Z" />
      <path className="cls-page-i-3" d="M31.49,25.94h7V42h-7Z" />
      <path
        className="cls-page-i-2"
        d="M31.49,21.78H28.71l2.08,16,2.78,2.09V23.86A2.08,2.08,0,0,0,31.49,21.78Z"
      />
      <path
        className="cls-page-i-3"
        d="M28.71,21.78H18.25v16H30.79v-14a2.08,2.08,0,0,0-2.08-2.08Z"
      />
      <path
        className="cls-page-i-4"
        d="M31.49,37.83H27.32v2.09A2.08,2.08,0,0,0,29.4,42h2.09a2.09,2.09,0,0,0,0-4.17Z"
      />
      <path
        className="cls-page-i-5"
        d="M18.25,56.67V65c6.35,0,11.5-1.86,11.5-4.17s-5.15-4.16-11.5-4.16Z"
      />
      <path
        className="cls-page-i-6"
        d="M27,60.83c0-2.3-3.9-4.16-8.72-4.16-6.35,0-11.5,1.86-11.5,4.16S11.9,65,18.25,65C23.07,65,27,63.14,27,60.83Z"
      />
      <path
        className="cls-page-i-7"
        d="M18.25,19a1.39,1.39,0,0,0-1.39,1.39V59.45l1.39,2.77,1.39-2.77V20.39A1.39,1.39,0,0,0,18.25,19Z"
      />
    </svg>
  ) : (
    <svg
      id="Layer_pageIcon_light"
      data-name="Layer pageIcon_light"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 70 65"
      width={70}
      height={65}
      {...props}
    >
      <defs>
        <style>
          {
            '.cls-page-i-1,.cls-page-i-2{fill:#5384ee;}.cls-page-i-1{fill-opacity:0.1;}.cls-page-i-3{fill:#3f73e3;}.cls-page-i-4{fill:#072252;}.cls-page-i-5{fill:#ececec;}.cls-page-i-6{fill:#fff;}.cls-page-i-7{fill:#8493a8;}'
          }
        </style>
      </defs>
      <circle className="cls-page-i-1" cx={41.5} cy={28.5} r={28.5} />
      <path className="cls-page-i-2" d="M38.47,25.94l-4,8,4,8h2.78V25.94Z" />
      <path className="cls-page-i-3" d="M31.49,25.94h7V42h-7Z" />
      <path
        className="cls-page-i-2"
        d="M31.49,21.78H28.71l2.08,16,2.78,2.09V23.86A2.08,2.08,0,0,0,31.49,21.78Z"
      />
      <path
        className="cls-page-i-3"
        d="M28.71,21.78H18.25v16H30.79v-14a2.08,2.08,0,0,0-2.08-2.08Z"
      />
      <path
        className="cls-page-i-4"
        d="M31.49,37.83H27.32v2.09A2.08,2.08,0,0,0,29.4,42h2.09a2.09,2.09,0,0,0,0-4.17Z"
      />
      <path
        className="cls-page-i-5"
        d="M18.25,56.67V65c6.35,0,11.5-1.86,11.5-4.17s-5.15-4.16-11.5-4.16Z"
      />
      <path
        className="cls-page-i-6"
        d="M27,60.83c0-2.3-3.9-4.16-8.72-4.16-6.35,0-11.5,1.86-11.5,4.16S11.9,65,18.25,65C23.07,65,27,63.14,27,60.83Z"
      />
      <path
        className="cls-page-i-7"
        d="M18.25,19a1.39,1.39,0,0,0-1.39,1.39V59.45l1.39,2.77,1.39-2.77V20.39A1.39,1.39,0,0,0,18.25,19Z"
      />
    </svg>
  );
}

export default PageIcon;
