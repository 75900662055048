import classes from './SocialConnectionsModal.request.module.scss';

import React from 'react';
import Typography from '@lobox/uikit/Typography';
import {
  QueryKeys,
  useGlobalDispatch,
  useTranslation,
  useUpdateInfinityData,
} from '@lobox/utils';
import { useHistory, useParams } from '@lobox/utils';
import useGetFollowRequest from '@shared/hooks/api-hook/useGetFollowRequest';
import { ModalBody } from '@shared/components/molecules/Modal';
import PendingButton from '@shared/components/molecules/PendingButton/PendingButton';
import { NetworkItem } from '@shared/components/molecules';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import { useQueryClient } from '@tanstack/react-query';
import InfiniteLoading from '@shared/components/molecules/InfiniteLoading';
import InfiniteScroll from '@shared/components/Organism/InfiniteScroll';
import { SocialConnectionsTabs } from '@shared/constants/enums';
import type { ValueOf } from '@lobox/utils';
import SocialConnectionsModalActions from './SocialConnectionsModal.actions';

export interface SocialConnectionsRequestProps {
  activeTab: ValueOf<typeof SocialConnectionsTabs>;
}

const SocialConnectionsRequest: React.FC<SocialConnectionsRequestProps> = ({
  activeTab,
}) => {
  const { t } = useTranslation();
  const dispatch = useGlobalDispatch();
  const history = useHistory();
  const { reFetchAppObject, getAppObjectPropValue } = useGetAppObject();
  const queryClient = useQueryClient();
  const params = useParams<{ username: string }>();
  const username = getAppObjectPropValue({
    userKey: 'username',
    pageKey: 'username',
  });
  const onClickAvatar = (item: any) => {
    dispatch({
      type: 'CLOSE_OBJECT_NETWORK_MODAL',
    });
    history.push(`/${item.username}`);
  };
  const {
    data,
    totalElements,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetFollowRequest(activeTab === SocialConnectionsTabs.followers);

  const { remove: removeFollowRequest } = useUpdateInfinityData([
    QueryKeys.getMyFollowRequests,
  ]);
  const { remove: removePendingRequest } = useUpdateInfinityData([
    QueryKeys.getPendingRequests,
  ]);

  const handleRefetch = () => {
    reFetchAppObject();

    if (params?.username === username) {
      queryClient.refetchQueries([QueryKeys.objectDetail, username]);
    }
  };
  const handleSuccessPending = (id: string) => () => {
    removePendingRequest(id);
    handleRefetch();
  };
  const handleSuccessActions = (id: string) => () => {
    removeFollowRequest(id);
    handleRefetch();
  };

  return (
    <ModalBody className={classes.contentClassName}>
      {totalElements === 0 ? (
        <Typography size={16} height={20}>
          {t('y_h_n_follow_req')}
        </Typography>
      ) : (
        <InfiniteScroll
          data={data}
          onEndReached={fetchNextPage}
          hasMore={!isFetchingNextPage && hasNextPage}
          loadingComponent={<InfiniteLoading />}
          renderItem={(item: any) => (
            <NetworkItem
              onClickAvatar={() => onClickAvatar(item)}
              item={{ ...item, objectId: item.id }}
              action={
                <>
                  {activeTab === SocialConnectionsTabs.following && (
                    <>
                      <PendingButton
                        object={{ id: item.id, username: item.username }}
                        onSuccess={handleSuccessPending(item.id)}
                        className={classes.followBtn}
                      />
                    </>
                  )}
                  {activeTab === SocialConnectionsTabs.followers && (
                    <SocialConnectionsModalActions
                      id={item.id}
                      username={item.username}
                      onSuccessDecline={handleSuccessActions(item.id)}
                      onSuccessAccept={handleSuccessActions(item.id)}
                    />
                  )}
                </>
              }
              withHover
            />
          )}
        />
      )}
    </ModalBody>
  );
};

export default SocialConnectionsRequest;
