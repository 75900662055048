import classes from './index.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Spinner from '@lobox/uikit/Spinner';
import type FlexProps from '@lobox/uikit/FlexProps';

export interface InfiniteLoadingProps extends Omit<FlexProps, 'className'> {
  className?: string;
  size?: number;
}

const InfiniteLoading = ({
  className,
  size = 22,
  ...rest
}: InfiniteLoadingProps): JSX.Element => {
  return (
    <Flex className={cnj(classes.infiniteLoadingRoot, className)} {...rest}>
      <Spinner size={size} />
    </Flex>
  );
};

export default InfiniteLoading;
