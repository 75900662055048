import classes from './PendingButton.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Button from '@lobox/uikit/Button';
import Typography from '@lobox/uikit/Typography';
import {
  preventClickHandler,
  QueryKeys,
  useTranslation,
  useUpdateQueryData,
} from '@lobox/utils';
import useCancelFollow from '@shared/hooks/api-hook/useCancelFollow';
import isFunction from 'lodash/isFunction';
import { useQueryClient } from '@tanstack/react-query';

export interface PendingButtonProps {
  onClick?: (e: any) => void;
  className?: string;
  onSuccess?: (data: unknown, variables: void, context: unknown) => void;
  object: { id: string; username: string };
}

const PendingButton = ({
  className,
  onClick,
  onSuccess,
  object,
}: PendingButtonProps): JSX.Element => {
  const { t } = useTranslation();
  const { cancelCall, isLoading } = useCancelFollow();
  const { replace } = useUpdateQueryData([
    QueryKeys.objectDetail,
    object?.username,
  ]);
  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData([
    QueryKeys.objectDetail,
    object?.username,
  ]);

  const onClickHandler = (e: any) => {
    preventClickHandler(e);
    if (userData) {
      replace({
        network: {
          ...userData.network,
          follow: false,
          followStatus: null,
        },
      });
    }
    if (isFunction(onClick)) {
      onClick(e);
    }
    cancelCall(object.id, {
      onSuccess,
    });
  };

  return (
    <Button
      fullWidth
      isLoading={isLoading}
      schema="ghost-orange"
      className={cnj(classes.followingButton, classes.pendingBtn, className)}
      onClick={onClickHandler}
    >
      <Typography
        font="700"
        color="pendingOrange"
        className={cnj(classes.pendingLabel)}
        size={15}
      >
        {t('pending')}
      </Typography>
      <Typography
        font="700"
        color="pendingOrange"
        className={cnj(classes.cancelLabel)}
        size={15}
      >
        {t('cancel')}
      </Typography>
    </Button>
  );
};

export default PendingButton;
