import React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

const CreateJob: React.FC = (props: any) => {
  const { isDark } = useTheme();
  return isDark ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={70}
      height={67}
      fill="none"
      {...props}
    >
      <circle cx={41.5} cy={28.5} r={28.5} fill="#393939" />
      <path
        fill="#072252"
        d="M27.517 30.072a.727.727 0 0 1-.188-.025c-1.673-.45-3.92-.699-6.33-.699-2.407 0-4.655.248-6.328.7a.724.724 0 1 1-.377-1.4c1.793-.482 4.174-.748 6.706-.748s4.913.266 6.706.749a.724.724 0 0 1-.189 1.423Z"
      />
      <path
        fill="#072252"
        d="M14.483 28.624c-.4 0-.724.324-.724.724v3.62h1.448v-3.62c0-.4-.324-.724-.724-.724ZM27.518 28.624c-.4 0-.724.324-.724.724v3.62h1.448v-3.62c0-.4-.325-.724-.724-.724Z"
      />
      <path
        fill="#3F73E3"
        d="M37.655 64.1H4.345a2.897 2.897 0 0 1-2.897-2.896V47.452h39.104v13.752c0 1.6-1.297 2.896-2.897 2.896Z"
      />
      <path
        fill="#5384EE"
        d="M20.276 46.003H2.896v15.2c0 .8.649 1.449 1.449 1.449h15.207c.4 0 .724-.324.724-.724V46.003ZM21.724 46.003v15.925c0 .4.324.724.724.724h15.207c.8 0 1.448-.648 1.448-1.448v-15.2H21.724Z"
      />
      <path
        fill="#5384EE"
        d="M37.655 49.624H4.345A4.345 4.345 0 0 1 0 45.279V33.686c0-.8.648-1.448 1.448-1.448h39.104c.8 0 1.448.648 1.448 1.448V45.28c0 2.4-1.945 4.345-4.345 4.345Z"
      />
      <path
        fill="#3F73E3"
        d="M37.655 51.072a5.75 5.75 0 0 0 2.896-.784v-1.777a4.324 4.324 0 0 1-2.896 1.113H4.345a4.323 4.323 0 0 1-2.897-1.113v1.777a5.75 5.75 0 0 0 2.897.784h33.31Z"
      />
      <path
        fill="#fff"
        d="M22.448 43.83h-2.896c-.4 0-.724.325-.724.725v5.069c0 .4.324.724.724.724h2.896c.4 0 .724-.324.724-.724v-5.069c0-.4-.324-.724-.724-.724Z"
      />
      <path
        fill="#fff"
        d="M23.173 52.52h-4.345a.724.724 0 0 1-.724-.724v-3.62c0-.4.324-.724.724-.724h4.345c.4 0 .724.324.724.724v3.62c0 .4-.324.724-.724.724Z"
      />
      <path
        fill="#ECECEC"
        d="M22.448 52.52h-2.896v-2.896c0-.4.324-.724.724-.724h1.448c.4 0 .724.324.724.724v2.896Z"
      />
      <path
        fill="#fff"
        d="M21 41.659a1.448 1.448 0 1 0 0-2.897 1.448 1.448 0 0 0 0 2.897Z"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={70}
      height={67}
      fill="none"
      {...props}
    >
      <circle cx={41.5} cy={28.5} r={28.5} fill="#5384EE" fillOpacity={0.1} />
      <path
        fill="#072252"
        d="M27.517 30.072a.727.727 0 0 1-.188-.025c-1.673-.45-3.92-.699-6.33-.699-2.407 0-4.655.248-6.328.7a.724.724 0 1 1-.377-1.4c1.793-.482 4.174-.748 6.706-.748s4.913.266 6.706.749a.724.724 0 0 1-.189 1.423Z"
      />
      <path
        fill="#072252"
        d="M14.483 28.624c-.4 0-.724.324-.724.724v3.62h1.448v-3.62c0-.4-.324-.724-.724-.724ZM27.518 28.624c-.4 0-.724.324-.724.724v3.62h1.448v-3.62c0-.4-.325-.724-.724-.724Z"
      />
      <path
        fill="#3F73E3"
        d="M37.655 64.1H4.345a2.897 2.897 0 0 1-2.897-2.896V47.452h39.104v13.752c0 1.6-1.297 2.896-2.897 2.896Z"
      />
      <path
        fill="#5384EE"
        d="M20.276 46.003H2.896v15.2c0 .8.649 1.449 1.449 1.449h15.207c.4 0 .724-.324.724-.724V46.003ZM21.724 46.003v15.925c0 .4.324.724.724.724h15.207c.8 0 1.448-.648 1.448-1.448v-15.2H21.724Z"
      />
      <path
        fill="#5384EE"
        d="M37.655 49.624H4.345A4.345 4.345 0 0 1 0 45.279V33.686c0-.8.648-1.448 1.448-1.448h39.104c.8 0 1.448.648 1.448 1.448V45.28c0 2.4-1.945 4.345-4.345 4.345Z"
      />
      <path
        fill="#3F73E3"
        d="M37.655 51.072a5.75 5.75 0 0 0 2.896-.784v-1.777a4.324 4.324 0 0 1-2.896 1.113H4.345a4.323 4.323 0 0 1-2.897-1.113v1.777a5.75 5.75 0 0 0 2.897.784h33.31Z"
      />
      <path
        fill="#fff"
        d="M22.448 43.83h-2.896c-.4 0-.724.325-.724.725v5.069c0 .4.324.724.724.724h2.896c.4 0 .724-.324.724-.724v-5.069c0-.4-.324-.724-.724-.724Z"
      />
      <path
        fill="#fff"
        d="M23.173 52.52h-4.345a.724.724 0 0 1-.724-.724v-3.62c0-.4.324-.724.724-.724h4.345c.4 0 .724.324.724.724v3.62c0 .4-.324.724-.724.724Z"
      />
      <path
        fill="#ECECEC"
        d="M22.448 52.52h-2.896v-2.896c0-.4.324-.724.724-.724h1.448c.4 0 .724.324.724.724v2.896Z"
      />
      <path
        fill="#fff"
        d="M21 41.659a1.448 1.448 0 1 0 0-2.897 1.448 1.448 0 0 0 0 2.897Z"
      />
    </svg>
  );
};
export default CreateJob;
