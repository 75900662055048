import React from 'react';
import type { TypographyProps } from '../Typography';
import Typography from '../Typography';
import type { BaseButtonProps } from '../Button/BaseButton';
import BaseButton from '../Button/BaseButton';
import classes from './Link.typography.module.scss';

export interface TextLinkProps {
  className?: string;
  to?: string;
  href?: string;
  linkProps?: BaseButtonProps;
  typographyProps?: Omit<TypographyProps, 'children'>;
}

const TextLink: React.FC<TextLinkProps> = ({
  className,
  to,
  href,
  linkProps = {},
  typographyProps = {},
  children,
}) => {
  return (
    <BaseButton
      className={className}
      // @ts-ignore
      to={to}
      href={href}
      {...linkProps}
    >
      <Typography className={classes.linkTypographyRoot} {...typographyProps}>
        {children}
      </Typography>
    </BaseButton>
  );
};

export default TextLink;
