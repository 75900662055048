import * as React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

function CreateIcon(props: any) {
  const { isDark } = useTheme();

  return isDark ? (
    <svg
      width={70}
      height={65}
      viewBox="0 0 70 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={41.5} cy={28.5} r={28.5} fill="#393939" />
      <path
        d="M34.2952 64H5.1333C4.51253 64 4.00928 63.5023 4.00928 62.8882V28.8981C4.00928 28.2841 4.51245 27.7863 5.1333 27.7863H34.2951C34.9159 27.7863 35.4191 28.284 35.4191 28.8981V62.8883C35.4191 63.5021 34.916 64 34.2952 64Z"
        fill="white"
      />
      <path
        d="M11.1934 27.7863L10.212 30.3476C10.212 30.7325 10.5274 31.0444 10.9164 31.0444C11.3055 31.0444 11.6208 30.7325 11.6208 30.3476L12.6022 27.7863C12.6022 27.7863 11.1934 27.7863 11.1934 27.7863Z"
        fill="#CDCDCD"
      />
      <path
        d="M14.7572 27.7863L13.7758 30.3475C13.7758 30.7324 14.0911 31.0443 14.4802 31.0443C14.8691 31.0443 15.1846 30.7324 15.1846 30.3475L16.166 27.7863C16.1659 27.7863 14.7572 27.7863 14.7572 27.7863Z"
        fill="#CDCDCD"
      />
      <path
        d="M18.3218 27.7863L17.3404 30.3475C17.3404 30.7324 17.6557 31.0443 18.0448 31.0443C18.4338 31.0443 18.7492 30.7324 18.7492 30.3475L19.7306 27.7863C19.7305 27.7863 18.3218 27.7863 18.3218 27.7863Z"
        fill="#CDCDCD"
      />
      <path
        d="M7.62941 27.7863L6.64801 30.3476C6.64801 30.7325 6.96336 31.0444 7.35241 31.0444C7.74138 31.0444 8.05681 30.7325 8.05681 30.3476L9.03821 27.7863C9.03814 27.7863 7.62941 27.7863 7.62941 27.7863Z"
        fill="#CDCDCD"
      />
      <path
        d="M32.577 27.7863L31.5956 30.3475C31.5956 30.7324 31.9109 31.0443 32.3 31.0443C32.689 31.0443 33.0044 30.7324 33.0044 30.3475L33.9858 27.7863C33.9857 27.7863 32.577 27.7863 32.577 27.7863Z"
        fill="#CDCDCD"
      />
      <path
        d="M29.0139 27.7863L28.0325 30.3475C28.0325 30.7324 28.3479 31.0443 28.7369 31.0443C29.1259 31.0443 29.4413 30.7324 29.4413 30.3475L30.4227 27.7863C30.4227 27.7863 29.0139 27.7863 29.0139 27.7863Z"
        fill="#CDCDCD"
      />
      <path
        d="M21.8861 27.7863L20.9047 30.3475C20.9047 30.7324 21.22 31.0443 21.6091 31.0443C21.998 31.0443 22.3135 30.7324 22.3135 30.3475L23.2949 27.7863C23.2948 27.7863 21.8861 27.7863 21.8861 27.7863Z"
        fill="#CDCDCD"
      />
      <path
        d="M25.4492 27.7863L24.4678 30.3475C24.4678 30.7324 24.7831 31.0443 25.1722 31.0443C25.5611 31.0443 25.8766 30.7324 25.8766 30.3475L26.858 27.7863C26.8579 27.7863 25.4492 27.7863 25.4492 27.7863Z"
        fill="#CDCDCD"
      />
      <path
        d="M14.3792 61.0986H7.70253C7.51009 61.0986 7.35406 60.9443 7.35406 60.754V55.5594C7.35406 55.369 7.51009 55.2146 7.70253 55.2146H14.3792C14.5716 55.2146 14.7277 55.3689 14.7277 55.5594V60.754C14.7277 60.9443 14.5716 61.0986 14.3792 61.0986Z"
        fill="#5384EE"
        fillOpacity={0.2}
      />
      <path
        d="M7.35229 31.0443C6.96332 31.0443 6.64789 30.7325 6.64789 30.3476V25.6967C6.64797 25.3118 6.96332 25 7.35229 25C7.74126 25 8.05669 25.3118 8.05669 25.6967V30.3476C8.05669 30.7325 7.74134 31.0443 7.35229 31.0443Z"
        fill="#3F73E3"
      />
      <path
        d="M10.9163 31.0443C10.5273 31.0443 10.2119 30.7325 10.2119 30.3476V25.6967C10.2119 25.3118 10.5272 25 10.9163 25C11.3053 25 11.6207 25.3118 11.6207 25.6967V30.3476C11.6207 30.7325 11.3052 31.0443 10.9163 31.0443Z"
        fill="#3F73E3"
      />
      <path
        d="M14.4803 31.0443C14.0914 31.0443 13.7759 30.7325 13.7759 30.3476V25.6967C13.7759 25.3118 14.0913 25 14.4803 25C14.8694 25 15.1847 25.3118 15.1847 25.6967V30.3476C15.1847 30.7325 14.8693 31.0443 14.4803 31.0443Z"
        fill="#3F73E3"
      />
      <path
        d="M18.0447 31.0443C17.6557 31.0443 17.3403 30.7325 17.3403 30.3476V25.6967C17.3403 25.3118 17.6556 25 18.0447 25C18.4337 25 18.7491 25.3118 18.7491 25.6967V30.3476C18.7491 30.7325 18.4337 31.0443 18.0447 31.0443Z"
        fill="#3F73E3"
      />
      <path
        d="M21.6089 31.0443C21.22 31.0443 20.9045 30.7325 20.9045 30.3476V25.6967C20.9045 25.3118 21.2199 25 21.6089 25C21.9979 25 22.3133 25.3118 22.3133 25.6967V30.3476C22.3133 30.7325 21.9979 31.0443 21.6089 31.0443Z"
        fill="#3F73E3"
      />
      <path
        d="M25.1721 31.0443C24.7831 31.0443 24.4677 30.7325 24.4677 30.3476V25.6967C24.4677 25.3118 24.783 25 25.1721 25C25.561 25 25.8765 25.3118 25.8765 25.6967V30.3476C25.8764 30.7325 25.561 31.0443 25.1721 31.0443Z"
        fill="#3F73E3"
      />
      <path
        d="M28.7369 31.0443C28.3479 31.0443 28.0325 30.7325 28.0325 30.3476V25.6967C28.0325 25.3118 28.3478 25 28.7369 25C29.1258 25 29.4413 25.3118 29.4413 25.6967V30.3476C29.4413 30.7325 29.1259 31.0443 28.7369 31.0443Z"
        fill="#3F73E3"
      />
      <path
        d="M32.2999 31.0443C31.9109 31.0443 31.5955 30.7325 31.5955 30.3476V25.6967C31.5955 25.3118 31.9108 25 32.2999 25C32.6888 25 33.0043 25.3118 33.0043 25.6967V30.3476C33.0042 30.7325 32.6888 31.0443 32.2999 31.0443Z"
        fill="#3F73E3"
      />
      <path
        d="M32.3003 38.154H7.35229C6.96332 38.154 6.64789 37.8422 6.64789 37.4573C6.64789 37.0724 6.96324 36.7606 7.35229 36.7606H32.3003C32.6893 36.7606 33.0047 37.0724 33.0047 37.4573C33.0047 37.8422 32.6893 38.154 32.3003 38.154Z"
        fill="#5384EE"
        fillOpacity={0.2}
      />
      <path
        d="M32.3003 41.3219H7.35229C6.96332 41.3219 6.64789 41.01 6.64789 40.6251C6.64789 40.2402 6.96324 39.9284 7.35229 39.9284H32.3003C32.6893 39.9284 33.0047 40.2402 33.0047 40.6251C33.0047 41.01 32.6893 41.3219 32.3003 41.3219Z"
        fill="#5384EE"
        fillOpacity={0.2}
      />
      <path
        d="M32.3003 44.4898H7.35229C6.96332 44.4898 6.64789 44.178 6.64789 43.7931C6.64789 43.4082 6.96324 43.0963 7.35229 43.0963H32.3003C32.6893 43.0963 33.0047 43.4082 33.0047 43.7931C33.0047 44.178 32.6893 44.4898 32.3003 44.4898Z"
        fill="#5384EE"
        fillOpacity={0.2}
      />
      <path
        d="M32.3003 47.6572H7.35229C6.96332 47.6572 6.64789 47.3453 6.64789 46.9604C6.64789 46.5755 6.96324 46.2637 7.35229 46.2637H32.3003C32.6893 46.2637 33.0047 46.5755 33.0047 46.9604C33.0047 47.3453 32.6893 47.6572 32.3003 47.6572Z"
        fill="#5384EE"
        fillOpacity={0.2}
      />
      <path
        d="M32.3003 50.825H7.35229C6.96332 50.825 6.64789 50.5131 6.64789 50.1283C6.64789 49.7434 6.96324 49.4315 7.35229 49.4315H32.3003C32.6893 49.4315 33.0047 49.7434 33.0047 50.1283C33.0047 50.5131 32.6893 50.825 32.3003 50.825Z"
        fill="#5384EE"
        fillOpacity={0.2}
      />
      <g clipPath="url(#clip0_1_8614)">
        <path
          d="M53.189 32.3644L51.796 30.9867L42.858 40.5164L48.0815 40.172L53.189 35.1201C53.9582 34.3591 53.9582 33.1254 53.189 32.3644Z"
          fill="#4D719A"
        />
        <path
          d="M51.7954 30.9867L51.0989 30.2979C50.3295 29.5369 49.0823 29.537 48.313 30.2979L43.2056 35.3498L43.9021 41.5499L51.7954 33.7424C52.5646 32.9814 52.5646 31.7477 51.7954 30.9867Z"
          fill="#072252"
        />
        <path
          d="M39.0279 40.8609L45.9926 42.2387L48.082 40.172L46.6891 38.7942L39.0279 40.8609Z"
          fill="#F7FCFF"
        />
        <path
          d="M46.69 38.7943L43.2076 35.3499L41.1182 37.4165L41.8147 43.6167L46.69 38.7943Z"
          fill="#E1F5FE"
        />
        <path
          d="M25.3102 57.8739L22.0268 59.7439C21.7718 60.3885 21.5415 60.9705 21.5415 60.9705C21.3851 61.366 21.7797 61.7564 22.1796 61.6016L25.136 60.4573L25.3102 57.8739Z"
          fill="#072252"
        />
        <path
          d="M25.3096 57.8739L22.6979 58.0462C22.6979 58.0462 22.3429 58.9433 22.0262 59.7439L25.2681 58.4891L25.3096 57.8739Z"
          fill="#485363"
        />
        <path
          d="M29.4889 53.7407L23.743 59.0797L25.136 60.4575L29.1407 58.9074L29.4889 53.7407Z"
          fill="#FED2A4"
        />
        <path
          d="M27.7472 57.5296L29.4884 53.7407L24.2649 54.0852L22.6978 58.0463L23.7425 59.0797L27.7472 57.5296Z"
          fill="#FFE5C2"
        />
        <path
          d="M27.748 57.5295L29.1409 58.9073L45.9926 42.2389L44.5996 40.8611L33.388 46.4397L27.748 57.5295Z"
          fill="#5384EE"
        />
        <path
          d="M24.2643 54.0836L41.1134 37.4176L44.5952 40.8616L27.7461 57.5276L24.2643 54.0836Z"
          fill="#3F73E3"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_8614">
          <rect
            width={32.2597}
            height={31.9091}
            fill="white"
            transform="translate(21.5067 29.7272)"
          />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width={70}
      height={65}
      viewBox="0 0 70 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={41.5} cy={28.5} r={28.5} fill="#5384EE" fillOpacity={0.1} />
      <path
        d="M34.2952 64H5.1333C4.51253 64 4.00928 63.5023 4.00928 62.8882V28.8981C4.00928 28.2841 4.51245 27.7863 5.1333 27.7863H34.2951C34.9159 27.7863 35.4191 28.284 35.4191 28.8981V62.8883C35.4191 63.5021 34.916 64 34.2952 64Z"
        fill="white"
      />
      <path
        d="M11.1934 27.7863L10.212 30.3476C10.212 30.7325 10.5274 31.0444 10.9164 31.0444C11.3055 31.0444 11.6208 30.7325 11.6208 30.3476L12.6022 27.7863C12.6022 27.7863 11.1934 27.7863 11.1934 27.7863Z"
        fill="#CDCDCD"
      />
      <path
        d="M14.7572 27.7863L13.7758 30.3475C13.7758 30.7324 14.0911 31.0443 14.4802 31.0443C14.8691 31.0443 15.1846 30.7324 15.1846 30.3475L16.166 27.7863C16.1659 27.7863 14.7572 27.7863 14.7572 27.7863Z"
        fill="#CDCDCD"
      />
      <path
        d="M18.3218 27.7863L17.3404 30.3475C17.3404 30.7324 17.6557 31.0443 18.0448 31.0443C18.4338 31.0443 18.7492 30.7324 18.7492 30.3475L19.7306 27.7863C19.7305 27.7863 18.3218 27.7863 18.3218 27.7863Z"
        fill="#CDCDCD"
      />
      <path
        d="M7.62941 27.7863L6.64801 30.3476C6.64801 30.7325 6.96336 31.0444 7.35241 31.0444C7.74138 31.0444 8.05681 30.7325 8.05681 30.3476L9.03821 27.7863C9.03814 27.7863 7.62941 27.7863 7.62941 27.7863Z"
        fill="#CDCDCD"
      />
      <path
        d="M32.577 27.7863L31.5956 30.3475C31.5956 30.7324 31.9109 31.0443 32.3 31.0443C32.689 31.0443 33.0044 30.7324 33.0044 30.3475L33.9858 27.7863C33.9857 27.7863 32.577 27.7863 32.577 27.7863Z"
        fill="#CDCDCD"
      />
      <path
        d="M29.0139 27.7863L28.0325 30.3475C28.0325 30.7324 28.3479 31.0443 28.7369 31.0443C29.1259 31.0443 29.4413 30.7324 29.4413 30.3475L30.4227 27.7863C30.4227 27.7863 29.0139 27.7863 29.0139 27.7863Z"
        fill="#CDCDCD"
      />
      <path
        d="M21.8861 27.7863L20.9047 30.3475C20.9047 30.7324 21.22 31.0443 21.6091 31.0443C21.998 31.0443 22.3135 30.7324 22.3135 30.3475L23.2949 27.7863C23.2948 27.7863 21.8861 27.7863 21.8861 27.7863Z"
        fill="#CDCDCD"
      />
      <path
        d="M25.4492 27.7863L24.4678 30.3475C24.4678 30.7324 24.7831 31.0443 25.1722 31.0443C25.5611 31.0443 25.8766 30.7324 25.8766 30.3475L26.858 27.7863C26.8579 27.7863 25.4492 27.7863 25.4492 27.7863Z"
        fill="#CDCDCD"
      />
      <path
        d="M14.3792 61.0986H7.70253C7.51009 61.0986 7.35406 60.9443 7.35406 60.754V55.5594C7.35406 55.369 7.51009 55.2146 7.70253 55.2146H14.3792C14.5716 55.2146 14.7277 55.3689 14.7277 55.5594V60.754C14.7277 60.9443 14.5716 61.0986 14.3792 61.0986Z"
        fill="#5384EE"
        fillOpacity={0.2}
      />
      <path
        d="M7.35229 31.0443C6.96332 31.0443 6.64789 30.7325 6.64789 30.3476V25.6967C6.64797 25.3118 6.96332 25 7.35229 25C7.74126 25 8.05669 25.3118 8.05669 25.6967V30.3476C8.05669 30.7325 7.74134 31.0443 7.35229 31.0443Z"
        fill="#3F73E3"
      />
      <path
        d="M10.9163 31.0443C10.5273 31.0443 10.2119 30.7325 10.2119 30.3476V25.6967C10.2119 25.3118 10.5272 25 10.9163 25C11.3053 25 11.6207 25.3118 11.6207 25.6967V30.3476C11.6207 30.7325 11.3052 31.0443 10.9163 31.0443Z"
        fill="#3F73E3"
      />
      <path
        d="M14.4803 31.0443C14.0914 31.0443 13.7759 30.7325 13.7759 30.3476V25.6967C13.7759 25.3118 14.0913 25 14.4803 25C14.8694 25 15.1847 25.3118 15.1847 25.6967V30.3476C15.1847 30.7325 14.8693 31.0443 14.4803 31.0443Z"
        fill="#3F73E3"
      />
      <path
        d="M18.0447 31.0443C17.6557 31.0443 17.3403 30.7325 17.3403 30.3476V25.6967C17.3403 25.3118 17.6556 25 18.0447 25C18.4337 25 18.7491 25.3118 18.7491 25.6967V30.3476C18.7491 30.7325 18.4337 31.0443 18.0447 31.0443Z"
        fill="#3F73E3"
      />
      <path
        d="M21.6089 31.0443C21.22 31.0443 20.9045 30.7325 20.9045 30.3476V25.6967C20.9045 25.3118 21.2199 25 21.6089 25C21.9979 25 22.3133 25.3118 22.3133 25.6967V30.3476C22.3133 30.7325 21.9979 31.0443 21.6089 31.0443Z"
        fill="#3F73E3"
      />
      <path
        d="M25.1721 31.0443C24.7831 31.0443 24.4677 30.7325 24.4677 30.3476V25.6967C24.4677 25.3118 24.783 25 25.1721 25C25.561 25 25.8765 25.3118 25.8765 25.6967V30.3476C25.8764 30.7325 25.561 31.0443 25.1721 31.0443Z"
        fill="#3F73E3"
      />
      <path
        d="M28.7369 31.0443C28.3479 31.0443 28.0325 30.7325 28.0325 30.3476V25.6967C28.0325 25.3118 28.3478 25 28.7369 25C29.1258 25 29.4413 25.3118 29.4413 25.6967V30.3476C29.4413 30.7325 29.1259 31.0443 28.7369 31.0443Z"
        fill="#3F73E3"
      />
      <path
        d="M32.2999 31.0443C31.9109 31.0443 31.5955 30.7325 31.5955 30.3476V25.6967C31.5955 25.3118 31.9108 25 32.2999 25C32.6888 25 33.0043 25.3118 33.0043 25.6967V30.3476C33.0042 30.7325 32.6888 31.0443 32.2999 31.0443Z"
        fill="#3F73E3"
      />
      <path
        d="M32.3003 38.154H7.35229C6.96332 38.154 6.64789 37.8422 6.64789 37.4573C6.64789 37.0724 6.96324 36.7606 7.35229 36.7606H32.3003C32.6893 36.7606 33.0047 37.0724 33.0047 37.4573C33.0047 37.8422 32.6893 38.154 32.3003 38.154Z"
        fill="#5384EE"
        fillOpacity={0.2}
      />
      <path
        d="M32.3003 41.3219H7.35229C6.96332 41.3219 6.64789 41.01 6.64789 40.6251C6.64789 40.2402 6.96324 39.9284 7.35229 39.9284H32.3003C32.6893 39.9284 33.0047 40.2402 33.0047 40.6251C33.0047 41.01 32.6893 41.3219 32.3003 41.3219Z"
        fill="#5384EE"
        fillOpacity={0.2}
      />
      <path
        d="M32.3003 44.4898H7.35229C6.96332 44.4898 6.64789 44.178 6.64789 43.7931C6.64789 43.4082 6.96324 43.0963 7.35229 43.0963H32.3003C32.6893 43.0963 33.0047 43.4082 33.0047 43.7931C33.0047 44.178 32.6893 44.4898 32.3003 44.4898Z"
        fill="#5384EE"
        fillOpacity={0.2}
      />
      <path
        d="M32.3003 47.6572H7.35229C6.96332 47.6572 6.64789 47.3453 6.64789 46.9604C6.64789 46.5755 6.96324 46.2637 7.35229 46.2637H32.3003C32.6893 46.2637 33.0047 46.5755 33.0047 46.9604C33.0047 47.3453 32.6893 47.6572 32.3003 47.6572Z"
        fill="#5384EE"
        fillOpacity={0.2}
      />
      <path
        d="M32.3003 50.825H7.35229C6.96332 50.825 6.64789 50.5131 6.64789 50.1283C6.64789 49.7434 6.96324 49.4315 7.35229 49.4315H32.3003C32.6893 49.4315 33.0047 49.7434 33.0047 50.1283C33.0047 50.5131 32.6893 50.825 32.3003 50.825Z"
        fill="#5384EE"
        fillOpacity={0.2}
      />
      <g clipPath="url(#clip0_1_8572)">
        <path
          d="M53.189 32.3644L51.796 30.9867L42.858 40.5164L48.0815 40.172L53.189 35.1201C53.9582 34.3591 53.9582 33.1254 53.189 32.3644Z"
          fill="#4D719A"
        />
        <path
          d="M51.7954 30.9867L51.0989 30.2979C50.3295 29.5369 49.0823 29.537 48.313 30.2979L43.2056 35.3498L43.9021 41.5499L51.7954 33.7424C52.5646 32.9814 52.5646 31.7477 51.7954 30.9867Z"
          fill="#072252"
        />
        <path
          d="M39.0279 40.8609L45.9926 42.2387L48.082 40.172L46.6891 38.7942L39.0279 40.8609Z"
          fill="#F7FCFF"
        />
        <path
          d="M46.69 38.7943L43.2076 35.3499L41.1182 37.4165L41.8147 43.6167L46.69 38.7943Z"
          fill="#E1F5FE"
        />
        <path
          d="M25.3102 57.8739L22.0268 59.7439C21.7718 60.3885 21.5415 60.9705 21.5415 60.9705C21.3851 61.366 21.7797 61.7564 22.1796 61.6016L25.136 60.4573L25.3102 57.8739Z"
          fill="#072252"
        />
        <path
          d="M25.3096 57.8739L22.6979 58.0462C22.6979 58.0462 22.3429 58.9433 22.0262 59.7439L25.2681 58.4891L25.3096 57.8739Z"
          fill="#485363"
        />
        <path
          d="M29.4889 53.7407L23.743 59.0797L25.136 60.4575L29.1407 58.9074L29.4889 53.7407Z"
          fill="#FED2A4"
        />
        <path
          d="M27.7472 57.5296L29.4884 53.7407L24.2649 54.0852L22.6978 58.0463L23.7425 59.0797L27.7472 57.5296Z"
          fill="#FFE5C2"
        />
        <path
          d="M27.748 57.5295L29.1409 58.9073L45.9926 42.2389L44.5996 40.8611L33.388 46.4397L27.748 57.5295Z"
          fill="#5384EE"
        />
        <path
          d="M24.2643 54.0836L41.1134 37.4176L44.5952 40.8616L27.7461 57.5276L24.2643 54.0836Z"
          fill="#3F73E3"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_8572">
          <rect
            width={32.2597}
            height={31.9091}
            fill="white"
            transform="translate(21.5067 29.7272)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CreateIcon;
