import classes from './CollectionModalForm.module.scss';

import React from 'react';
import Form from '@lobox/uikit/Form';
import Typography from '@lobox/uikit/Typography';
import SubmitButton from '@lobox/uikit/Form/SubmitButton';
import DynamicFormBuilder from '@lobox/uikit/Form/DynamicFormBuilder';
import { useTranslation, postEndpoints, QueryKeys } from '@lobox/utils';
import { useQueryClient } from '@tanstack/react-query';
import {
  ModalBody,
  ModalDialog,
  ModalFooter,
  ModalHeaderSimple,
} from '@shared/components/molecules/Modal';
import { useProfilePage } from '@shared/hooks';
import isFunction from 'lodash/isFunction';
import type { PrivacyValueType } from '@lobox/utils';

export interface CollectionModalFormProps {
  collectionItemKey: string | Array<string>;
  isOpen: boolean;
  onClose: (...args: any[]) => any;
  modalTitle: string;
  currentId?: string;
  initialValue?: {
    value: string | number;
    label: string;
    privacy: PrivacyValueType;
  };
}

const CollectionModalForm: React.FC<CollectionModalFormProps> = ({
  collectionItemKey,
  isOpen,
  onClose,
  modalTitle,
  currentId,
  initialValue,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { getObjectProp } = useProfilePage();
  const userId = getObjectProp({
    userKey: 'id',
    pageKey: 'id',
  });

  const onSuccess = () => {
    if (isFunction(onClose)) {
      onClose();
    }

    queryClient.refetchQueries([QueryKeys.collections, '', userId], {
      active: true,
    });

    if (initialValue) {
      queryClient.refetchQueries(collectionItemKey, {
        active: true,
      });
    }
  };

  return (
    <Form
      initialValues={{
        collectionName: initialValue,
      }}
      onSuccess={onSuccess}
      transform={({
        collectionName: { value, privacy } = {},
      }: {
        collectionName: any;
      }) => ({
        access: privacy,
        name: value,
      })}
      url={
        initialValue
          ? postEndpoints.collection.item(currentId || '')
          : postEndpoints.collection.create
      }
      method={initialValue ? 'PUT' : 'POST'}
    >
      {() => (
        <ModalDialog isOpen={isOpen} onBack={onClose} onClose={onClose}>
          <ModalHeaderSimple title={modalTitle} />
          <ModalBody>
            {!initialValue && (
              <Typography
                color="primaryText"
                font="400"
                size={15}
                height={21}
                mb={20}
              >
                {t('collection_hint')}
              </Typography>
            )}
            <DynamicFormBuilder
              groups={[
                {
                  name: 'collectionName',
                  cp: 'input',
                  label: t('collection_name'),
                  helperText: t('collection_name_example'),
                  privateable: true,
                },
              ]}
            />
          </ModalBody>
          <ModalFooter>
            <SubmitButton
              label={initialValue ? t('save') : t('create')}
              className={classes.submitButton}
            />
          </ModalFooter>
        </ModalDialog>
      )}
    </Form>
  );
};

export default CollectionModalForm;
