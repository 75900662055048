import classes from './SocialConnectionsModal.requestButtont.module.scss';

import React from 'react';
import AvatarCard from '@lobox/uikit/AvatarCard';
import { useTranslation } from '@lobox/utils';

interface SocialConnectionsRequestButtonProps {
  onClick: () => void;
  totalElements?: number;
}

const SocialConnectionsRequestButton: React.FC<
  SocialConnectionsRequestButtonProps
> = ({ onClick, totalElements }) => {
  const { t } = useTranslation();

  return (
    <AvatarCard
      noHover
      onClick={onClick}
      data={{
        title: t('pending_requests'),
        subTitle: t('review_pen_req_b_y'),
      }}
      avatarProps={{
        badgeNumber: totalElements ? `${totalElements}` : undefined,
      }}
      containerProps={{ className: classes.itemWrap }}
      titleProps={{ height: 20, size: 16, font: '700' }}
      subTitleProps={{ height: 18, mt: 4, size: 14, color: 'border' }}
    />
  );
};

export default SocialConnectionsRequestButton;
