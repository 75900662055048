import classes from './SocialConnectionsModal.list.module.scss';

import React, { useEffect } from 'react';
import SearchInput from '@lobox/uikit/SearchInput';
import {
  preventClickHandler,
  QueryKeys,
  useDebounceState,
  useGlobalDispatch,
  useGlobalState,
  useInfiniteQuery,
  useTranslation,
  useUpdateInfinityData,
} from '@lobox/utils';
import { followStatus, SocialConnectionsTabs } from '@shared/constants/enums';
import PendingButton from '@shared/components/molecules/PendingButton/PendingButton';
import {
  FollowButton,
  FollowingButton,
  ModalBody,
  NetworkItem,
} from '@shared/components/molecules';
import InfiniteLoading from '@shared/components/molecules/InfiniteLoading';
import InfiniteScroll from '@shared/components/Organism/InfiniteScroll';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import { useParams } from '@lobox/utils';
import { useQueryClient } from '@tanstack/react-query';
import { SCOPES } from '@shared/constants/userRoles.scopes';
import useGetFollowRequest from '@shared/hooks/api-hook/useGetFollowRequest';
import RequestButtont from './SocialConnectionsModal.requestButtont';
import SocialConnectionsModalSkeleton from './SocialConnectionsModal.skeleton';
import type {
  QueryKeyType,
  IGetFollowers,
  PaginateResponse,
  ValueOf,
} from '@lobox/utils';
import useHasPermission from '../../../hooks/useHasPermission';
import SocialConnectionsListEmptyState from './SocialConnectionsModal.emptyState';

interface SocialConnectionsListProps {
  queryKey: QueryKeyType;
  apiFunc: Function;
  onClickRequestButton?: () => void;
  emptyMessage: React.ReactElement | string;
  emptyCaption: string;
  activeTab: ValueOf<typeof SocialConnectionsTabs>;
  isAuthUserVisible: boolean;
  placeholder: string;
}

const SocialConnectionsList: React.FC<SocialConnectionsListProps> = ({
  queryKey: qk,
  apiFunc,
  onClickRequestButton,
  emptyMessage,
  activeTab,
  isAuthUserVisible,
  emptyCaption = '',
  placeholder,
}) => {
  const { t } = useTranslation();
  const dispatch = useGlobalDispatch();
  const objectNetworkModal = useGlobalState('objectNetworkModal');
  const { setValue, debounceValue } = useDebounceState('', 500);
  const objectId = objectNetworkModal?.objectId;
  const queryKey = [qk, `${debounceValue} ${objectId}`];
  const { replace } = useUpdateInfinityData(queryKey as any);
  const params = useParams<{ username: string }>();
  const queryClient = useQueryClient();
  const hasAccess = useHasPermission([SCOPES.canFollowUnFollow]);
  const { reFetchAppObject, authUser, getAppObjectPropValue } =
    useGetAppObject();
  const appObjectUsername = getAppObjectPropValue({
    userKey: 'username',
    pageKey: 'username',
  });
  const appObjectId = getAppObjectPropValue({
    userKey: 'id',
    pageKey: 'id',
  });
  const enabled = !!objectId || !!debounceValue;
  const isFollowersTab = activeTab === SocialConnectionsTabs.followers;
  const isLoggedInUser =
    authUser?.id && authUser?.id === objectNetworkModal?.objectId;

  useEffect(() => {
    setValue('');
  }, [activeTab]);
  const { totalElements: followRequestCount } = useGetFollowRequest(
    isFollowersTab,
    isLoggedInUser
  );
  const {
    data = [],
    totalElements,
    fetchNextPage,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery<PaginateResponse<IGetFollowers>>(
    queryKey,
    {
      func: apiFunc,
      extraProps: {
        userId: objectId,
        pageId: objectId,
        text: debounceValue,
        companyPageId: objectId,
      },
    },
    {
      enabled,
    }
  );

  useEffect(() => {
    if (enabled) {
      refetch();
    }
  }, [debounceValue, refetch]);

  const dataWithAuthUser =
    totalElements > 0 && !debounceValue && !isLoading && isAuthUserVisible
      ? [
          {
            id: appObjectId,
            croppedImageUrl: getAppObjectPropValue({
              userKey: 'croppedImageUrl',
              pageKey: 'croppedImageUrl',
            }),
            fullName: getAppObjectPropValue({
              userKey: 'fullName',
              pageKey: 'title',
            }),
            usernameAtSign: `@${appObjectUsername}`,
            username: appObjectUsername,
          },
          ...data?.filter((item: any) => item.id !== appObjectId),
        ]
      : data;

  const visibleFollowRequestButton =
    [SocialConnectionsTabs.followers, SocialConnectionsTabs.following].includes(
      activeTab
    ) &&
    hasAccess &&
    isLoggedInUser &&
    followRequestCount > 0;
  const onClickAvatar = () => {
    dispatch({
      type: 'CLOSE_OBJECT_NETWORK_MODAL',
    });
  };

  const onSuccess =
    (item: any, itemProps = {}) =>
    () => {
      const newItem = { ...item, ...itemProps };
      replace(newItem);
      reFetchAppObject();
      if (params?.username === appObjectUsername) {
        queryClient.refetchQueries([QueryKeys.objectDetail, appObjectUsername]);
      }
    };

  const isFollow = (item: any) => !item.follow;
  const isFollowing = (item: any) =>
    item.follow && item.followStatus !== followStatus.PENDING;
  const isPending = (item: any) =>
    item.follow && item.followStatus === followStatus.PENDING;
  const clearSearch = () => setValue('');

  return (
    <>
      {(totalElements > 0 || debounceValue) && (
        <SearchInput
          key={activeTab}
          className={classes.searchInput}
          onChange={setValue}
          onClearSearch={clearSearch}
          placeholder={placeholder}
        />
      )}
      <ModalBody className={classes.contentClassName}>
        {visibleFollowRequestButton && (
          <RequestButtont
            totalElements={isFollowersTab ? followRequestCount : undefined}
            onClick={onClickRequestButton}
          />
        )}
        {totalElements === 0 && !isLoading ? (
          <SocialConnectionsListEmptyState
            caption={emptyCaption}
            message={debounceValue ? t('no_u_found') : emptyMessage}
          />
        ) : isLoading ? (
          <SocialConnectionsModalSkeleton
            className={
              debounceValue ? classes.skeleton : classes.skeletonWithSearch
            }
            visibleSearchInput={!debounceValue}
          />
        ) : (
          <InfiniteScroll
            data={dataWithAuthUser}
            onEndReached={fetchNextPage}
            hasMore={!isFetchingNextPage && hasNextPage}
            loadingComponent={<InfiniteLoading />}
            renderItem={(item) => (
              <NetworkItem
                wrapperClassName={classes.networkItem}
                item={{
                  ...item,
                  objectId: item.id,
                }}
                onClickAvatar={() => onClickAvatar(item)}
                action={
                  hasAccess && appObjectId !== item.id ? (
                    <>
                      {isPending(item) && (
                        <PendingButton
                          object={{ id: item.id, username: item.username }}
                          onSuccess={onSuccess(item, {
                            follow: false,
                            followStatus: null,
                          })}
                          className={classes.followBtn}
                          onClick={preventClickHandler}
                        />
                      )}
                      {isFollowing(item) && (
                        <FollowingButton
                          onClick={preventClickHandler}
                          className={classes.followBtn}
                          object={{
                            isPage: item.isPage,
                            id: item.id,
                            username: item.username,
                            name: item.fullName,
                          }}
                          onSuccess={
                            onSuccess(item, {
                              follow: false,
                              followStatus: null,
                            }) as any
                          }
                        />
                      )}
                      {isFollow(item) && (
                        <FollowButton
                          className={classes.followBtn}
                          back={item?.back}
                          object={{
                            id: item.id,
                            isPage: item.isPage,
                            username: item.username,
                          }}
                          onSuccess={onSuccess(item, {
                            follow: true,
                            followStatus: item.privateProfile
                              ? followStatus.PENDING
                              : followStatus.ACCEPTED,
                          })}
                        />
                      )}
                    </>
                  ) : undefined
                }
                withHover
              />
            )}
          />
        )}
      </ModalBody>
    </>
  );
};

export default SocialConnectionsList;
