import React from 'react';
import EmptySearchSvg from '@shared/svg/EmptySearchSvg';
import EmptyState from './EmptyState';
import type { EmptyStateProps } from './EmptyState';

type PeopleEmptyStateProps = Omit<EmptyStateProps, 'image' | 'className'>;

const PeopleEmptyState: React.FC<PeopleEmptyStateProps> = (props) => (
  <EmptyState image={<EmptySearchSvg />} {...props} />
);

export default PeopleEmptyState;
