import React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

const CreateSchedule: React.FC = (props: any) => {
  const { isDark } = useTheme();
  return isDark ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={70}
      height={65}
      fill="none"
      {...props}
    >
      <circle cx={41.5} cy={28.5} r={28.5} fill="#393939" />
      <path
        fill="#3F73E3"
        d="M35.132 64.25H1.868C.836 64.25 0 63.223 0 61.956V36.75h37v25.207c0 1.266-.836 2.293-1.868 2.293Z"
      />
      <path
        fill="#fff"
        d="m19.467 43.754 1.376 2.507c.158.287.418.492.72.566l2.631.64c.833.202 1.162 1.295.598 1.988l-1.78 2.189a1.28 1.28 0 0 0-.276.916l.25 2.902c.08.92-.78 1.595-1.565 1.23l-2.476-1.155a1.047 1.047 0 0 0-.89 0l-2.476 1.154c-.784.366-1.644-.31-1.565-1.23l.25-2.901a1.28 1.28 0 0 0-.275-.916l-1.78-2.19c-.564-.692-.236-1.785.597-1.988l2.631-.64c.302-.073.563-.278.72-.565l1.376-2.507c.436-.794 1.499-.794 1.934 0Z"
      />
      <path
        fill="#3F73E3"
        d="M22.91 54.578c-.225.599-.88.943-1.49.66l-2.475-1.155a1.047 1.047 0 0 0-.89 0l-2.477 1.154c-.609.284-1.263-.06-1.488-.659l-.077.884c-.079.92.781 1.595 1.565 1.23l2.476-1.155c.285-.133.607-.133.89 0l2.477 1.154c.784.366 1.644-.31 1.565-1.23l-.077-.883Z"
      />
      <path
        fill="#64A2FF"
        d="M35.132 28.91H1.868C.836 28.91 0 29.814 0 30.929V36.2h37v-5.272c0-1.115-.836-2.02-1.868-2.02Z"
      />
      <path fill="#83B4FF" d="M0 34.747h37V36.2H0v-1.454Z" />
      <path
        fill="#485363"
        d="M7.052 25.588c.817 0 1.478.716 1.478 1.598v4.687c0 .883-.661 1.598-1.477 1.598-.817 0-1.478-.715-1.478-1.598v-4.687c0-.882.661-1.598 1.477-1.598ZM29.948 25.588c-.817 0-1.478.716-1.478 1.598v4.687c0 .883.661 1.598 1.478 1.598.816 0 1.477-.715 1.477-1.598v-4.687c0-.882-.661-1.598-1.477-1.598Z"
      />
      <path
        fill="#072252"
        d="M7.053 30.767c-.817 0-1.478-.715-1.478-1.598v1.454c0 .883.661 1.598 1.478 1.598.816 0 1.477-.715 1.477-1.598V29.17c0 .883-.661 1.598-1.477 1.598ZM29.948 30.767c-.817 0-1.478-.715-1.478-1.598v1.454c0 .883.661 1.598 1.478 1.598.816 0 1.477-.715 1.477-1.598V29.17c0 .883-.661 1.598-1.477 1.598Z"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={70}
      height={65}
      fill="none"
      {...props}
    >
      <circle cx={41.5} cy={28.5} r={28.5} fill="#5384EE" fillOpacity={0.1} />
      <path
        fill="#3F73E3"
        d="M35.132 64.25H1.868C.836 64.25 0 63.223 0 61.956V36.75h37v25.207c0 1.266-.836 2.293-1.868 2.293Z"
      />
      <path
        fill="#fff"
        d="m19.467 43.754 1.376 2.507c.158.287.418.492.72.566l2.631.64c.833.202 1.162 1.295.598 1.988l-1.78 2.189a1.28 1.28 0 0 0-.276.916l.25 2.902c.08.92-.78 1.595-1.565 1.23l-2.476-1.155a1.047 1.047 0 0 0-.89 0l-2.476 1.154c-.784.366-1.644-.31-1.565-1.23l.25-2.901a1.28 1.28 0 0 0-.275-.916l-1.78-2.19c-.564-.692-.236-1.785.597-1.988l2.631-.64c.302-.073.563-.278.72-.565l1.376-2.507c.436-.794 1.499-.794 1.934 0Z"
      />
      <path
        fill="#3F73E3"
        d="M22.91 54.578c-.225.599-.88.943-1.49.66l-2.475-1.155a1.047 1.047 0 0 0-.89 0l-2.477 1.154c-.609.284-1.263-.06-1.488-.659l-.077.884c-.079.92.781 1.595 1.565 1.23l2.476-1.155c.285-.133.607-.133.89 0l2.477 1.154c.784.366 1.644-.31 1.565-1.23l-.077-.883Z"
      />
      <path
        fill="#64A2FF"
        d="M35.132 28.91H1.868C.836 28.91 0 29.814 0 30.929V36.2h37v-5.272c0-1.115-.836-2.02-1.868-2.02Z"
      />
      <path fill="#83B4FF" d="M0 34.747h37V36.2H0v-1.454Z" />
      <path
        fill="#485363"
        d="M7.052 25.588c.817 0 1.478.716 1.478 1.598v4.687c0 .883-.661 1.598-1.477 1.598-.817 0-1.478-.715-1.478-1.598v-4.687c0-.882.661-1.598 1.477-1.598ZM29.948 25.588c-.817 0-1.478.716-1.478 1.598v4.687c0 .883.661 1.598 1.478 1.598.816 0 1.477-.715 1.477-1.598v-4.687c0-.882-.661-1.598-1.477-1.598Z"
      />
      <path
        fill="#072252"
        d="M7.053 30.767c-.817 0-1.478-.715-1.478-1.598v1.454c0 .883.661 1.598 1.478 1.598.816 0 1.477-.715 1.477-1.598V29.17c0 .883-.661 1.598-1.477 1.598ZM29.948 30.767c-.817 0-1.478-.715-1.478-1.598v1.454c0 .883.661 1.598 1.478 1.598.816 0 1.477-.715 1.477-1.598V29.17c0 .883-.661 1.598-1.477 1.598Z"
      />
    </svg>
  );
};
export default CreateSchedule;
