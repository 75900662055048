import React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

export interface PagesProps {
  className?: string;
}

const Pages: React.FC<PagesProps> = ({ className }) => {
  const { isDark } = useTheme();

  return (
    <svg
      width={390}
      height={225}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#prefix__clip0)">
        <path d="M228.924 0H85.311v145.192h143.613V0z" fill="#F1F4F9" />
        <path
          d="M223.506 4.567H90.729v136.011h132.777V4.567z"
          fill="url(#prefix__paint0_linear)"
        />
        <path
          d="M116.257 80.826H98.661v59.466h17.596V80.826z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path d="M210.044 124.403h-50.776v4.52h50.776v-4.52z" fill="#83B4FF" />
        <path
          d="M210.044 121.025h-50.776v19.267h50.776v-19.267z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path
          d="M166.921 101.235h-45.637v39.105h45.637v-39.105z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path
          d="M162.732 70.456h-37.258v39.104h37.258V70.456z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path
          d="M155.805 61.892h-23.461v54.043h23.461V61.892z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path
          d="M140.946 42.53h-1.955v54.043h1.955V42.53zM149.27 55.613h-1.956v54.043h1.956V55.613zM113.743 84.157l-12.066 3.235V65.793l12.066-3.234v21.598zM200.604 109.751h-33.739v15.699h33.739v-15.699z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path
          d="M197.587 77.83h-23.349v54.28h23.349V77.83z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path
          d="M194.571 93.576H177.59v-25.5l16.981 7.28v18.22z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path
          d="M103.8 92.006h-2.123v4.852h2.123v-4.852zM108.492 92.006h-2.122v4.852h2.122v-4.852zM113.184 92.006h-2.122v4.852h2.122v-4.852zM103.8 100.379h-2.123v4.852h2.123v-4.852zM108.492 100.379h-2.122v4.852h2.122v-4.852zM113.184 100.379h-2.122v4.852h2.122v-4.852zM210.044 133.442h-50.776v4.186h50.776v-4.186z"
          fill="#83B4FF"
        />
        <path
          d="M219.819 105.231h-15.137v35.062h15.137v-35.062z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path
          d="M152.398 115.697H133.07v24.595h19.328v-24.595zM111.062 73.643h-3.631v7.184h3.631v-7.184zM162.732 82.634h-37.258v6.137h37.258v-6.137zM181.444 82.634h-3.854v7.374h3.854v-7.374zM189.935 82.634h-3.854v7.374h3.854v-7.374zM181.444 92.91h-3.854v7.374h3.854V92.91zM189.935 92.91h-3.854v7.374h3.854V92.91zM181.444 103.138h-3.854v7.374h3.854v-7.374zM189.935 103.138h-3.854v7.374h3.854v-7.374zM181.444 113.414h-3.854v7.373h3.854v-7.373zM189.935 113.414h-3.854v7.373h3.854v-7.373zM181.444 123.689h-3.854v7.374h3.854v-7.374zM189.935 123.689h-3.854v7.374h3.854v-7.374z"
          fill="#83B4FF"
        />
        <path
          d="M223.506 4.567v8.468L109.944 140.578H90.729v-27.973L186.918 4.567h36.588z"
          fill="url(#prefix__paint1_linear)"
        />
        <path
          d="M175.411 4.567L90.729 99.713V83.49l70.215-78.923h14.467z"
          fill="url(#prefix__paint2_linear)"
        />
        <path
          d="M223.506 56.136v16.27l-60.719 68.172H148.32l75.186-84.442z"
          fill="url(#prefix__paint3_linear)"
        />
        <path
          d="M221.048 4.567L99.89 140.578h-7.541L213.507 4.567h7.541z"
          fill="url(#prefix__paint4_linear)"
        />
      </g>
      <path
        d="M8.308 224.916s-23.409-49.283 10.838-78.714c44.168-37.951 101.234-6.223 122.649-7.156 21.414-.918 22.623-37.338 86.604-41.696 49.465-3.369 130.708-3.633 113.381 55.869-13.176 45.232 60.139 21.801 45.868 71.781l-379.34-.084z"
        fill="url(#prefix__paint5_linear)"
      />
      <g clipPath="url(#prefix__clip1)">
        <path
          opacity={0.2}
          d="M303.263 215.486c26.771 0 48.473-1.015 48.473-2.266 0-1.251-21.702-2.265-48.473-2.265-26.77 0-48.472 1.014-48.472 2.265 0 1.251 21.702 2.266 48.472 2.266z"
          fill="#513CAF"
        />
        <path
          d="M303.263 202.218c-2.366 0-4.198-1.618-4.198-3.56v-28.09h8.473v28.09c-.076 1.942-1.908 3.56-4.275 3.56z"
          fill="#4E355B"
        />
        <path
          d="M281.203 210.955c-.382 0-.764-.129-.993-.324-.61-.453-.687-1.294-.076-1.812l22.061-20.711c.305-.259.687-.453 1.145-.453.458 0 .839.129 1.145.388l22.747 20.711c.611.518.535 1.36-.076 1.813-.611.517-1.603.453-2.137-.065l-21.679-19.676-20.992 19.741c-.306.259-.764.388-1.145.388z"
          fill="#4E355B"
        />
        <path
          opacity={0.2}
          d="M307.538 170.568h-8.473v13.269h8.473v-13.269z"
          fill="#5C2C5B"
        />
        <path
          d="M281.203 214.321c3.035 0 5.496-2.087 5.496-4.66 0-2.574-2.461-4.661-5.496-4.661-3.036 0-5.496 2.087-5.496 4.661 0 2.573 2.46 4.66 5.496 4.66z"
          fill="#4E355B"
        />
        <path
          d="M281.202 211.926c1.476 0 2.672-1.014 2.672-2.265 0-1.251-1.196-2.265-2.672-2.265-1.475 0-2.671 1.014-2.671 2.265 0 1.251 1.196 2.265 2.671 2.265z"
          fill="#74607E"
        />
        <path
          d="M326.011 214.321c3.035 0 5.496-2.087 5.496-4.66 0-2.574-2.461-4.661-5.496-4.661-3.036 0-5.496 2.087-5.496 4.661 0 2.573 2.46 4.66 5.496 4.66z"
          fill="#4E355B"
        />
        <path
          d="M345.095 175.358h-83.281s-2.519-31.714-2.519-57.733c0-13.268.687-23.041 1.298-29.449.534-5.113 5.572-9.061 11.679-9.061h62.518c6.106 0 11.144 3.948 11.679 9.061.61 6.02 1.221 15.469 1.221 29.449-.076 28.996-2.595 57.733-2.595 57.733z"
          fill="url(#prefix__paint6_linear)"
        />
        <path
          d="M263.188 163.254a1.65 1.65 0 01-1.069-.388l-2.977-2.524c-5.19-4.401-8.396-8.802-8.396-17.152v-5.695c0-.712.687-1.295 1.526-1.295.84 0 1.527.583 1.527 1.295v5.695c0 7.508 2.748 11.262 7.481 15.275l2.977 2.524c.61.518.61 1.295 0 1.812a1.43 1.43 0 01-1.069.453z"
          fill="url(#prefix__paint7_linear)"
        />
        <path
          d="M260.669 142.349h-10.763c-2.367 0-4.351-1.618-4.351-3.689v-1.036c0-2.006 1.908-3.689 4.351-3.689h10.763c2.366 0 4.351 1.618 4.351 3.689v1.036c-.076 2.006-1.985 3.689-4.351 3.689z"
          fill="url(#prefix__paint8_linear)"
        />
        <path
          d="M342.27 163.255c-.381 0-.763-.13-1.068-.389-.611-.517-.611-1.294 0-1.812l2.977-2.524c4.732-4.013 7.48-7.832 7.48-15.275v-5.695c0-.712.687-1.295 1.527-1.295.84 0 1.527.583 1.527 1.295v5.695c0 8.349-3.206 12.751-8.397 17.152l-2.977 2.524c-.229.194-.611.324-1.069.324z"
          fill="url(#prefix__paint9_linear)"
        />
        <path
          d="M344.79 142.349h10.763c2.366 0 4.351-1.618 4.351-3.689v-1.036c0-2.006-1.909-3.689-4.351-3.689H344.79c-2.367 0-4.352 1.618-4.352 3.689v1.036c.077 2.006 1.985 3.689 4.352 3.689z"
          fill="url(#prefix__paint10_linear)"
        />
        <path
          opacity={0.5}
          d="M339.98 90.635h-71.754c-.534 0-.916-.388-.916-.776 0-.453.458-.777.916-.777h71.754c.535 0 .916.388.916.777 0 .453-.458.776-.916.776zM339.98 100.344h-71.754c-.534 0-.916-.388-.916-.777 0-.453.458-.776.916-.776h71.754c.535 0 .916.388.916.776 0 .389-.458.777-.916.777zM339.98 109.987h-71.754c-.534 0-.916-.388-.916-.776 0-.453.458-.777.916-.777h71.754c.535 0 .916.388.916.777 0 .453-.458.776-.916.776zM339.98 119.696h-71.754c-.534 0-.916-.388-.916-.777 0-.453.458-.776.916-.776h71.754c.535 0 .916.388.916.776 0 .389-.458.777-.916.777zM339.98 129.34h-71.754c-.534 0-.916-.388-.916-.777 0-.453.458-.776.916-.776h71.754c.535 0 .916.388.916.776 0 .453-.458.777-.916.777z"
          fill="#F1F4F9"
          fillOpacity={0.5}
        />
        <path
          d="M351.736 164.355v8.284c0 3.625-3.435 6.537-7.71 6.537h-81.525c-4.275 0-7.71-2.912-7.71-6.537v-8.284c0-3.625 3.435-6.537 7.71-6.537h81.525c4.198 0 7.557 2.783 7.71 6.278v.259z"
          fill="#777FD9"
        />
        <path
          d="M326.011 211.926c1.476 0 2.672-1.014 2.672-2.265 0-1.251-1.196-2.265-2.672-2.265-1.475 0-2.672 1.014-2.672 2.265 0 1.251 1.197 2.265 2.672 2.265z"
          fill="#74607E"
        />
        <path
          opacity={0.2}
          d="M351.736 168.303v4.336c0 3.625-3.435 6.537-7.71 6.537h-81.525c-4.275 0-7.71-2.912-7.71-6.537v-4.336h96.945z"
          fill="#A0759C"
        />
        <path
          d="M230.898 126.751h-87.479c-2.366 0-4.198-1.618-4.198-3.56V81.445c0-2.007 1.908-3.56 4.198-3.56h87.479c2.367 0 4.199 1.618 4.199 3.56v41.746c0 1.942-1.832 3.56-4.199 3.56z"
          fill="url(#prefix__paint11_linear)"
        />
        <path
          d="M211.662 111.411h-49.007c-1.45 0-2.672-1.036-2.672-2.265v-6.926c0-1.229 1.222-2.265 2.672-2.265h49.007c1.45 0 2.671 1.036 2.671 2.265v6.926c0 1.229-1.221 2.265-2.671 2.265z"
          fill="url(#prefix__paint12_linear)"
        />
        <path
          d="M196.395 143.643h-18.473v-36.245c0-1.294 1.222-2.33 2.748-2.33h12.977c1.527 0 2.748 1.036 2.748 2.33v36.245z"
          fill="url(#prefix__paint13_linear)"
        />
        <path
          d="M204.334 150.568H170.06c-1.45 0-2.672-1.035-2.672-2.265v-4.983c0-1.23 1.222-2.266 2.672-2.266h34.274c1.45 0 2.672 1.036 2.672 2.266v4.983c0 1.23-1.222 2.265-2.672 2.265z"
          fill="url(#prefix__paint14_linear)"
        />
        <path
          opacity={0.2}
          d="M187.159 96.59c4.469 0 8.091-3.072 8.091-6.861 0-3.79-3.622-6.86-8.091-6.86-4.469 0-8.092 3.07-8.092 6.86 0 3.789 3.623 6.86 8.092 6.86z"
          fill="#5C2C5B"
        />
        <path
          d="M192.121 86.105c0 .323-2.214.517-4.962.517-2.748 0-4.962-.258-4.962-.517 0-.324 2.214-.518 4.962-.518 2.748 0 4.962.259 4.962.518zM192.121 89.729c0 .324-2.214.518-4.962.518-2.748 0-4.962-.26-4.962-.518 0-.259 2.214-.518 4.962-.518 2.748-.065 4.962.194 4.962.518zM192.121 93.289c0 .323-2.214.518-4.962.518-2.748 0-4.962-.26-4.962-.518 0-.324 2.214-.518 4.962-.518 2.748 0 4.962.259 4.962.518z"
          fill="#4E355B"
        />
        <path d="M326.087 128.174h-14.656v16.634h14.656v-16.634z" fill="#fff" />
        <path
          d="M320.82 134.841c-1.374-3.56-6.107-5.696-10.458-4.79-4.427.907-6.87 4.596-5.496 8.155 1.374 3.56 6.107 5.696 10.458 4.79 4.428-.971 6.87-4.595 5.496-8.155zm-6.488 5.437c-2.596.517-5.267-.712-6.107-2.783-.763-2.072.611-4.207 3.206-4.725 2.595-.518 5.267.712 6.107 2.783.763 2.071-.687 4.207-3.206 4.725z"
          fill="#fff"
        />
        <path
          d="M267.997 146.75h-51.144c-1.374 0-2.443-.906-2.443-2.071s1.069-2.071 2.443-2.071h51.144c1.374 0 2.443.906 2.443 2.071 0 1.1-1.145 2.071-2.443 2.071z"
          fill="url(#prefix__paint15_linear)"
        />
        <path
          d="M162.808 135.812H125.48v9.967h37.328v-9.967z"
          fill="url(#prefix__paint16_linear)"
        />
        <path
          d="M160.67 137.754h-32.594v-.712l32.594-.647v1.359zM160.67 139.372h-32.594v-.777l32.594.194v.583z"
          fill="#fff"
        />
        <path
          d="M363.491 144.549H120.366V225h243.125v-80.451z"
          fill="#D7E9FF"
        />
        <path d="M354.636 152.834h-225.11v4.66h225.11v-4.66z" fill="#fff" />
      </g>
      <g clipPath="url(#prefix__clip2)">
        <path
          d="M35.555 164.44v1.533c0 .541.206.979.446.979h65.81c.257 0 .462-.438.462-.979v-1.533c0-.54-.205-.979-.462-.979h-65.81c-.24 0-.446.439-.446.979z"
          fill="url(#prefix__paint17_linear)"
        />
        <path
          d="M35.555 164.44v1.533c0 .541.189.979.411.979h52.258c.222 0 .41-.438.41-.979v-1.533c0-.54-.188-.979-.41-.979H35.966c-.222 0-.41.439-.41.979z"
          fill="url(#prefix__paint18_linear)"
        />
        <path
          d="M52.22 225.005h46.724v-58.048H52.22v58.048z"
          fill="url(#prefix__paint19_linear)"
        />
        <path
          d="M39.122 225.005h46.723v-58.048H39.122v58.048z"
          fill="#D7E9FF"
        />
        <path
          d="M41.638 168.587H83.34v53.2H41.638v-53.2zm41.24.394H42.083v52.441h40.812v-52.441h-.017z"
          fill="#fff"
        />
        <path
          d="M83.118 186.199H41.86v.379h41.258v-.379zM83.118 204.132H41.86v.38h41.258v-.38zM58.257 172.019h7.659c.377 0 .668-.263.668-.57 0-.321-.308-.569-.668-.569h-7.659c-.377 0-.668.263-.668.569-.017.322.291.57.668.57zM58.257 189.572h7.659c.377 0 .668-.263.668-.569 0-.322-.308-.57-.668-.57h-7.659c-.377 0-.668.263-.668.57-.017.306.291.569.668.569zM58.257 207.855h7.659c.377 0 .668-.262.668-.569 0-.321-.308-.57-.668-.57h-7.659c-.377 0-.668.263-.668.57-.017.321.291.569.668.569z"
          fill="#fff"
        />
      </g>
      <g clipPath="url(#prefix__clip3)">
        <path
          d="M385.381 42.308h-43.779c-.409 0-.727-.272-.727-.621V5.428c0-.35.318-.621.727-.621h43.779c.408 0 .727.271.727.62v36.259c0 .33-.319.62-.727.62z"
          fill="#C0D8FB"
        />
        <path
          d="M383.495 40.677H343.51c-.363 0-.658-.252-.658-.563V7.001c0-.31.295-.563.658-.563h39.985c.363 0 .659.252.659.563v33.113c0 .31-.296.563-.659.563z"
          fill="#D7E9FF"
        />
        <path
          d="M362.548 9.854c0-.446.409-.795.932-.795.522 0 .931.349.931.795 0 .447-.409.796-.931.796-.5 0-.932-.35-.932-.796zM370.114 10.98c.25-.389.817-.524 1.272-.31.454.213.613.698.363 1.086-.25.388-.818.524-1.272.31-.454-.213-.613-.698-.363-1.086zM376.588 15.386c.432-.233 1.022-.136 1.295.233.273.369.159.874-.273 1.107-.431.233-1.022.135-1.295-.233-.272-.37-.159-.874.273-1.107zM379.792 22.18c.522 0 .931.35.931.796 0 .446-.409.795-.931.795-.523 0-.932-.35-.932-.796 0-.427.432-.795.932-.795zM378.156 30.08a.718.718 0 010 1.125c-.364.31-.954.31-1.318 0a.718.718 0 010-1.125c.364-.311.954-.311 1.318 0zM372.272 35.126a.718.718 0 010 1.126c-.364.31-.954.31-1.318 0a.718.718 0 010-1.126c.364-.31.954-.31 1.318 0zM362.548 37.727c0-.446.409-.796.932-.796.522 0 .931.35.931.796 0 .446-.409.796-.931.796-.5 0-.932-.35-.932-.796zM356.846 10.98c-.25-.389-.818-.524-1.273-.31-.454.213-.613.698-.363 1.086.25.388.818.524 1.272.31.454-.213.613-.698.364-1.086zM350.371 15.386c-.432-.233-1.022-.136-1.295.233-.272.369-.159.873.273 1.106.431.233 1.022.136 1.295-.233.272-.369.159-.873-.273-1.106zM347.145 22.18c-.522 0-.931.35-.931.796 0 .446.409.795.931.795.523 0 .932-.35.932-.796.023-.427-.409-.795-.932-.795zM348.781 30.08a.718.718 0 000 1.125c.364.31.955.31 1.318 0a.717.717 0 000-1.125c-.341-.311-.931-.311-1.318 0zM354.665 35.126a.718.718 0 000 1.126c.364.31.954.31 1.318 0a.718.718 0 000-1.126c-.341-.31-.932-.31-1.318 0zM363.229 22.994c0-.446.409-.795.932-.795.522 0 .931.35.931.795 0 .447-.409.796-.931.796-.5 0-.932-.368-.932-.796z"
          fill="#5384EE"
        />
        <path d="M364.298 12.979h-.25v9.996h.25V12.98z" fill="#5384EE" />
        <path
          d="M370.018 18.564l-6.201 4.159.477.519 6.201-4.16-.477-.518z"
          fill="#5384EE"
        />
      </g>
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={157.117}
          y1={4.831}
          x2={157.117}
          y2={140.578}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DCE9FC" />
          <stop offset={1} stopColor="#DCE9FC" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={98.859}
          y1={172.958}
          x2={160.388}
          y2={12.594}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear"
          x1={90.713}
          y1={52.144}
          x2={175.425}
          y2={52.144}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint3_linear"
          x1={148.311}
          y1={98.367}
          x2={223.516}
          y2={98.367}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint4_linear"
          x1={92.364}
          y1={72.587}
          x2={221.029}
          y2={72.587}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint5_linear"
          x1={195}
          y1={96.404}
          x2={195}
          y2={225}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={isDark ? '#363636' : '#DCE9FC'} />
          <stop offset={1} stopColor={isDark ? '#242424' : '#fff'} />
        </linearGradient>
        <linearGradient
          id="prefix__paint6_linear"
          x1={303.493}
          y1={79.115}
          x2={303.493}
          y2={175.358}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset={1} stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="prefix__paint7_linear"
          x1={257.73}
          y1={136.2}
          x2={257.73}
          y2={163.254}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset={1} stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="prefix__paint8_linear"
          x1={255.287}
          y1={133.935}
          x2={255.287}
          y2={142.349}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset={1} stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="prefix__paint9_linear"
          x1={347.728}
          y1={136.265}
          x2={347.728}
          y2={163.255}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset={1} stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="prefix__paint10_linear"
          x1={350.171}
          y1={133.935}
          x2={350.171}
          y2={142.349}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset={1} stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="prefix__paint11_linear"
          x1={139.287}
          y1={102.341}
          x2={235.179}
          y2={102.341}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="prefix__paint12_linear"
          x1={187.158}
          y1={99.955}
          x2={187.158}
          y2={111.411}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint13_linear"
          x1={187.159}
          y1={105.068}
          x2={187.159}
          y2={143.643}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint14_linear"
          x1={187.197}
          y1={141.054}
          x2={187.197}
          y2={150.568}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint15_linear"
          x1={242.425}
          y1={142.608}
          x2={242.425}
          y2={146.75}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint16_linear"
          x1={144.144}
          y1={135.812}
          x2={144.144}
          y2={145.779}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint17_linear"
          x1={68.914}
          y1={163.461}
          x2={68.914}
          y2={166.952}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint18_linear"
          x1={62.095}
          y1={163.461}
          x2={62.095}
          y2={166.952}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7B9EDD" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint19_linear"
          x1={75.582}
          y1={225.005}
          x2={75.582}
          y2={166.957}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <clipPath id="prefix__clip0">
          <path
            fill="#fff"
            transform="translate(85.311)"
            d="M0 0h143.613v145.192H0z"
          />
        </clipPath>
        <clipPath id="prefix__clip1">
          <path
            fill="#fff"
            transform="translate(120.366 77.885)"
            d="M0 0h243.125v147.115H0z"
          />
        </clipPath>
        <clipPath id="prefix__clip2">
          <path
            fill="#fff"
            transform="translate(35.555 163.461)"
            d="M0 0h66.718v61.538H0z"
          />
        </clipPath>
        <clipPath id="prefix__clip3">
          <path
            fill="#fff"
            transform="translate(340.875 4.808)"
            d="M0 0h45.233v37.5H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Pages;
