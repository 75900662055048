import * as React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

function EmptySearchResult(props: any) {
  const { theme } = useTheme();
  const { isDark } = theme;

  return isDark ? (
    <svg
      width={220}
      height={149}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.14 148.304s-19.485-37.158 1.838-77.384c18.914-35.685 48.768-7.303 65.677-43.62C98.725-22.226 213.826-2.887 199.25 73.31c-7.052 36.871 32.019 38.219 17.481 75.07l-208.59-.076Z"
        fill="url(#a)"
      />
      <path
        opacity={0.5}
        d="M179.421 57.822H40.804c-1.314 0-2.356 1.367-2.356 3.09l-.272 80.836c0 1.724 1.042 3.091 2.356 3.091l138.934-1.486c1.315 0 2.357-1.367 2.357-3.091v-79.35c-.045-1.723-1.088-3.09-2.402-3.09Z"
        fill="#fff"
        fillOpacity={0.1}
      />
      <path
        opacity={0.05}
        d="M163.697 75.002H54.127c-1.088 0-1.994 1.13-1.994 2.615V90.99c0 1.427.86 2.615 1.994 2.615h109.57c1.088 0 1.994-1.13 1.994-2.615V77.617c-.045-1.426-.906-2.615-1.994-2.615Z"
        fill="#F1F4F9"
      />
      <path
        opacity={0.1}
        d="M72.924 75.283h-18.99c-.83 0-1.522 1.095-1.522 2.536v12.965c0 1.383.657 2.536 1.522 2.536h18.955c.83 0 1.522-1.152 1.522-2.535V77.875c.034-1.44-.657-2.593-1.487-2.593Z"
        fill="#F1F4F9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M178.56 63.48h-3.67c-.181 0-.363.238-.363.476s.182.475.363.475h3.67c.182 0 .363-.237.363-.475s-.136-.476-.363-.476ZM178.56 65.738h-3.67c-.181 0-.363.238-.363.476 0 .237.182.475.363.475h3.67c.182 0 .363-.237.363-.475s-.136-.476-.363-.476ZM178.56 67.938h-3.67c-.181 0-.363.237-.363.475s.182.475.363.475h3.67c.182 0 .363-.237.363-.475s-.136-.475-.363-.475Z"
        fill="#8493A8"
        fillOpacity={0.2}
      />
      <path
        d="M47.057 65.916c0 1.189-.725 2.08-1.586 2.08-.906 0-1.586-.95-1.586-2.08 0-1.13.725-2.08 1.586-2.08.86 0 1.586.95 1.586 2.08Z"
        fill="#8493A8"
        fillOpacity={0.1}
      />
      <path
        d="M55.758 65.916c0 1.189-.725 2.08-1.586 2.08-.906 0-1.586-.95-1.586-2.08 0-1.13.725-2.08 1.586-2.08.86 0 1.586.95 1.586 2.08Z"
        fill="#8493A8"
        fillOpacity={0.2}
      />
      <path
        d="M64.594 65.916c0 1.189-.725 2.08-1.586 2.08-.906 0-1.586-.95-1.586-2.08 0-1.13.725-2.08 1.586-2.08.86 0 1.586.95 1.586 2.08Z"
        fill="#8493A8"
        fillOpacity={0.1}
      />
      <path
        d="m47.162 121.424.404 1.248 5.712-.714s6.319 9.451 8.947 12.72c.253.356.556.713.91 1.188 1.668-4.755 5.055-19.732 1.618-20.921-5.106-1.724-17.59 6.479-17.59 6.479Z"
        fill="url(#b)"
      />
      <path
        d="M58.637 129.863c1.364 1.901 2.679 3.744 3.589 4.873.252.357.556.713.91 1.189 1.061-2.972 2.73-9.866 3.032-14.978-1.162.179-5.004 5.409-7.531 8.916Z"
        fill="url(#c)"
      />
      <path
        d="M53.278 122.018s6.318 9.45 8.947 12.719c2.628 3.209 8.593 12.778-7.481 12.065-16.125-.713-23.404-3.625-23.404-3.625l-.354-4.102 16.833-2.08s-10.514-1.426-9.25-13.135l14.709-1.842Z"
        fill="url(#d)"
      />
      <path
        d="m53.581 123.503.303-.595c1.618 2.437 6.218 9.213 8.34 11.828 1.012 1.248 2.579 3.507 3.084 5.706-1.213-3.388-5.004-6.538-8.24-10.223-4.144-4.755-3.487-6.716-3.487-6.716Z"
        fill="url(#e)"
      />
      <path
        d="M28.054 139.848h-.151c-.556.059-2.174.178-2.477 1.01-.354.951-.505 5.825 2.224 6.003 2.73.119-.505-2.377.404-3.447v-3.566Z"
        fill="#112B46"
      />
      <path
        d="m30.986 139.016 16.833-2.08s1.82.951 7.48 3.566c3.438 1.604 7.027 3.209 8.998 4.279-1.415 1.367-4.347 2.199-9.604 1.961-16.124-.713-23.403-3.625-23.403-3.625l-.304-4.101Z"
        fill="url(#f)"
      />
      <path
        d="M31.29 143.117s-2.023-1.011-3.135.356c-1.567 1.962-.1-3.625-.1-3.625l2.931-.832.303 4.101Z"
        fill="#FFE5C2"
      />
      <path
        d="M69.353 90.576 64.45 85.82a.612.612 0 0 1-.101-.654c.859-2.08.354-4.636-1.365-6.062-1.567-1.308-3.74-1.07-5.156.475a6.737 6.737 0 0 0-.708 1.01c-1.01 2.021-.657 4.636.91 6.182 1.466 1.426 3.59 1.426 5.055.178.05-.06.101-.06.152-.06.151-.059.303 0 .404.12l4.903 4.754a.737.737 0 0 0 .455.179c.152 0 .303-.12.404-.238.253-.476.203-.892-.05-1.13Zm-6.37-5.29c-1.06 1.486-2.93 1.724-4.194.475-1.264-1.248-1.466-3.447-.405-4.933 1.062-1.486 2.932-1.723 4.196-.475 1.263 1.189 1.465 3.447.404 4.933Z"
        fill="#fff"
      />
      <path
        d="M62.58 80.293c-1.264-1.248-3.134-1.07-4.195.475-1.062 1.486-.91 3.685.404 4.933 1.264 1.249 3.134 1.07 4.195-.475 1.062-1.426.86-3.685-.404-4.933Z"
        fill="#8493A8"
        fillOpacity={0.1}
      />
      <path
        d="M66.724 87.723c-.455-.06-.91.356-1.365.832-.1-.476-.354-1.189-.556-1.308-.354-.178-.758 3.15-.758 3.15l.253-.238c-.05.06-.05.12-.05.12l2.375 1.426c1.567-2.437 1.213-3.804.101-3.982Z"
        fill="#FFE5C2"
      />
      <path
        d="M38.618 113.042c.455-5.765 1.163-11.946 2.073-13.61.455-.833.86-1.427 1.365-1.784 0 0-4.448 3.329-6.268 8.44-.86 2.556.809 5.052 2.83 6.954Z"
        fill="url(#g)"
      />
      <path
        d="m50.7 97.41-.152-2.258a2.593 2.593 0 0 0 1.617-1.01c1.213-1.487 1.163-4.22.101-5.826-1.01.357-2.274.12-2.325.773-.05 1.01-.152 1.843-.152 1.843-.808-.595-1.718-.535-1.567.772.05.535.354 1.367.708 1.427 0 0 .253 2.199-1.617 3.09v.773l3.386.416Z"
        fill="#FFE5C2"
      />
      <path
        d="M44.078 89.802c.808-3.922 2.123-6.18 5.711-6.24 3.286-.12 3.69 3.09 3.69 3.09-.1 2.734-3.437 1.427-3.487 2.497-.05 1.01-.152 1.842-.152 1.842-.809-.594-1.719-.535-1.567.773.05.535.354 1.367.708 1.426 0 0 .252 2.2-1.618 3.091-.05-.06-4.094-2.556-3.285-6.479Z"
        fill="url(#h)"
      />
      <path
        d="M44.078 89.803c.808-3.923 2.123-6.181 5.711-6.24 2.174-.06 3.084 1.307 3.438 2.258-1.567-1.783-5.055-2.853-7.228 1.248-1.87 3.566-.708 7.846 2.78 7.37-.203.654-.607 1.367-1.466 1.783 0 0-4.044-2.496-3.235-6.419Z"
        fill="url(#i)"
      />
      <path
        d="M49.79 95.865c-.303-.238-.657-1.367-.657-1.367.354.475 1.415.654 1.415.654l.152 2.258-.758-.118c0-.654 0-1.308-.152-1.427Z"
        fill="#FED2A4"
      />
      <path
        d="M37.861 124.275s1.011-21.515 2.831-24.844c.758-1.367 1.415-2.08 2.578-2.377.708-.178 3.336-.297 4.044-.178 1.567.178 5.56.772 6.52 1.248 1.315.654 6.622 6.062 6.622 6.062l3.539-14.264 2.931 1.308s-2.88 20.861-5.156 20.861c-2.274.06-8.138-3.804-8.138-3.804s-.455 13.076 1.719 15.513c.05.059-9.099 4.993-17.49.475Z"
        fill="url(#j)"
      />
      <path
        d="M40.691 98.837c.809-.773 1.365-1.189 1.365-1.189-.505.416-.91.951-1.365 1.784-.91 1.664-1.617 7.845-2.072 13.61-.506-.475-.96-1.01-1.365-1.545.202-3.388.91-9.45 3.437-12.66Z"
        fill="#147AC6"
      />
      <path
        d="M50.397 106.742c-.303-.713 3.286 1.605 3.286 1.605s-.455 13.075 1.718 15.512c0 0-.657.357-1.718.773-1.264-4.398-.96-10.283-1.213-12.363-.304-2.853-1.77-4.814-2.073-5.527Z"
        fill="url(#k)"
      />
      <path
        d="M178.537 144.244c0 .178 0 .416-.051.654-.05.713-.707 1.129-.859 1.248.101 0 .152 0 .202.059 1.112.535 1.719.714 2.78.179.101-.06.152-.06.253 0-.051-.595-.152-1.486-.202-2.14h-2.123Z"
        fill="#FFE5C2"
      />
      <path
        d="M181.064 148.346V146.8c0-.297-.253-.475-.455-.356-1.061.535-1.668.416-2.78-.178-.859-.417-3.538 1.367-4.195 1.842-.354.297-.152.892.252.892h6.622c.303 0 .556-.298.556-.654Z"
        fill="#112B46"
      />
      <path
        d="M189.455 143.77c0 .178 0 .416-.051.653-.05.714-.707 1.13-.859 1.248.101 0 .152 0 .202.06 1.112.535 1.719.713 2.78.178.101-.059.152-.059.253 0-.051-.594-.152-1.486-.202-2.139h-2.123Z"
        fill="#FFE5C2"
      />
      <path
        d="M192.033 147.871v-1.545c0-.297-.253-.476-.455-.357-1.062.535-1.668.416-2.78-.178-.859-.416-3.538 1.367-4.196 1.842-.353.298-.151.892.253.892h6.622c.253 0 .505-.297.556-.654Z"
        fill="#112B46"
      />
      <path
        d="M189.96 98.955c0 1.07.657 4.042.657 7.37 0 6.36-2.73 14.859-2.73 14.859l4.6 22.942-3.892.119-8.593-22.407.607-24.012 9.351 1.13Z"
        fill="url(#l)"
      />
      <path
        d="M186.877 97.707s-.607 7.489-1.921 12.957c-1.061 4.577-8.896 11.59-8.896 11.59l5.105 22.288h-3.184l-11.374-21.693a4.253 4.253 0 0 1 .101-2.853l10.868-24.25 9.301 1.962Z"
        fill="url(#m)"
      />
      <path
        d="M160.087 93.664h-4.095s.202 2.853 4.448 2.853l-.353-2.853ZM182.025 69.356c.05-.237.101-.891.05-1.248-.101-1.486-.859-1.724-1.769-.832 0 0-.809-.594-1.213-1.724-.253-.653-1.365-.178-2.174-.356-.707 2.08-.05 4.992 1.466 6.3.607.535 1.214.594 1.972.594l.202 3.685 3.842-1.188-.051-2.2c-1.516.12-2.578-1.367-2.325-3.03Z"
        fill="#FFE5C2"
      />
      <path
        d="M179.042 144.542c-.808-1.605-10.766-21.575-9.3-24.012 1.516-2.556 8.34-18.306 8.34-18.306l-1.618-2.437s-.505.476-1.263 1.248l-8.442 18.96a4.247 4.247 0 0 0-.101 2.853l11.373 21.694h1.011Z"
        fill="url(#n)"
      />
      <path
        d="M177.727 79.757c.202-4.576 6.268-4.933 6.874-4.933.86 0 1.669.535 2.225 1.248.96 1.248 2.83 4.042 2.224 7.905-1.517 9.985 1.617 16.404 2.022 19.495-5.611 2.675-17.49-.654-17.49-.654l3.639-16.226.506-6.835Z"
        fill="url(#o)"
      />
      <path
        d="M173.228 90.634s3.69-15.75 8.391-14.86c5.207 1.011-3.386 20.565-3.386 20.565l-17.793.416-.354-3.328 13.142-2.793Z"
        fill="url(#p)"
      />
      <path
        d="M180.913 95.864c-.859 3.09-3.791 2.853-3.791 2.853l-1.617 4.695c-1.214-.297-1.972-.475-1.972-.475l1.416-6.42 3.235-.059s5.964-13.61 4.953-18.722c.202.357 1.668 4.458-2.224 18.128Z"
        fill="url(#q)"
      />
      <path
        d="M185.967 85.344c-6.015-.357-3.993-14.146-3.943-16.82.051-1.486-.859-2.14-1.719-1.248 0 0-.808-.595-1.213-1.724-.252-.713-1.617-.178-2.426-.357 1.719-8.142 9.857-7.786 13.446 3.27 3.083 9.509 5.459 17.473-4.145 16.879Z"
        fill="url(#r)"
      />
      <path
        d="M180.861 72.863c.152-.178.809-1.664.809-1.664-.304.357-.91.713-1.163.832-.101.06-.152.06-.152.06l.203 3.685.404-.12c-.101-1.069-.202-2.674-.101-2.793Z"
        fill="#FED2A4"
      />
      <path
        d="M176.565 83.621c.808-4.339 3.235-7.073 3.841-7.727-4.145 1.665-7.178 14.74-7.178 14.74l-13.142 2.794 14.709-1.01s.86-3.804 1.77-8.797Z"
        fill="url(#s)"
      />
      <path
        d="M183.238 61.63c4.398 2.317 6.875 10.698 5.712 23.536 6.117-1.189 3.943-8.321 1.213-16.702-3.588-11.055-11.727-11.411-13.445-3.269-.051 0 2.123-5.884 6.52-3.566Z"
        fill="url(#t)"
      />
      <defs>
        <linearGradient
          id="a"
          x1={110}
          y1={0}
          x2={110}
          y2={148.38}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#363636" />
          <stop offset={1} stopColor="#242424" />
        </linearGradient>
        <linearGradient
          id="b"
          x1={47.175}
          y1={125.297}
          x2={66.253}
          y2={125.297}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="c"
          x1={58.642}
          y1={128.443}
          x2={66.175}
          y2={128.443}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="d"
          x1={31.01}
          y1={134.441}
          x2={65.539}
          y2={134.441}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="e"
          x1={53.551}
          y1={131.683}
          x2={65.318}
          y2={131.683}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="f"
          x1={31.009}
          y1={141.864}
          x2={64.326}
          y2={141.864}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="g"
          x1={35.253}
          y1={99.996}
          x2={42.637}
          y2={112.005}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="h"
          x1={48.74}
          y1={83.59}
          x2={48.74}
          y2={96.226}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="i"
          x1={48.614}
          y1={83.591}
          x2={48.614}
          y2={96.167}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="j"
          x1={36.503}
          y1={95.45}
          x2={47.926}
          y2={130.73}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="k"
          x1={50.144}
          y1={109.354}
          x2={59.81}
          y2={119.729}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="l"
          x1={180.011}
          y1={121.058}
          x2={192.498}
          y2={121.058}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="m"
          x1={166.422}
          y1={120.167}
          x2={186.895}
          y2={120.167}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="n"
          x1={166.468}
          y1={122.186}
          x2={179.053}
          y2={122.186}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="o"
          x1={172.764}
          y1={79.351}
          x2={184.541}
          y2={106.078}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="p"
          x1={159.001}
          y1={78.943}
          x2={164.034}
          y2={100.355}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="q"
          x1={173.064}
          y1={81.651}
          x2={185.782}
          y2={100.813}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="r"
          x1={184.769}
          y1={59.629}
          x2={184.769}
          y2={85.263}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="s"
          x1={159.136}
          y1={78.568}
          x2={163.156}
          y2={96.517}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="t"
          x1={184.826}
          y1={59.629}
          x2={184.826}
          y2={85.054}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      width={220}
      height={149}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.046 148.304s-19.26-37.158 1.816-77.384c18.694-35.685 48.201-7.303 64.914-43.62 22.8-49.527 136.563-30.188 122.156 46.01-6.969 36.871 31.647 38.219 17.278 75.07l-206.164-.076Z"
        fill="url(#a)"
      />
      <path
        d="M177.335 57.823H40.33c-1.299 0-2.329 1.367-2.329 3.09l-.269 80.836c0 1.723 1.03 3.09 2.33 3.09l137.318-1.486c1.299 0 2.329-1.367 2.329-3.09v-79.35c-.045-1.723-1.075-3.09-2.374-3.09Z"
        fill="#fff"
      />
      <path
        d="M161.794 75.003H53.498c-1.075 0-1.97 1.13-1.97 2.615v13.373c0 1.427.85 2.615 1.97 2.615h108.296c1.075 0 1.971-1.13 1.971-2.615V77.618c-.045-1.426-.896-2.615-1.971-2.615Z"
        fill="#8493A8"
        fillOpacity={0.1}
      />
      <path
        d="M72.076 75.284H53.307c-.82 0-1.504 1.095-1.504 2.536v12.966c0 1.382.65 2.535 1.504 2.535h18.734c.821 0 1.505-1.153 1.505-2.535V77.876c.034-1.44-.65-2.593-1.47-2.593Z"
        fill="#8493A8"
        fillOpacity={0.2}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M176.484 63.48h-3.628c-.179 0-.358.238-.358.476s.179.475.358.475h3.628c.179 0 .358-.237.358-.475s-.134-.476-.358-.476ZM176.484 65.74h-3.628c-.179 0-.358.237-.358.475 0 .237.179.475.358.475h3.628c.179 0 .358-.237.358-.475s-.134-.476-.358-.476ZM176.484 67.938h-3.628c-.179 0-.358.238-.358.475 0 .238.179.476.358.476h3.628c.179 0 .358-.238.358-.475 0-.238-.134-.476-.358-.476Z"
        fill="#8493A8"
        fillOpacity={0.2}
      />
      <path
        d="M46.51 65.918c0 1.188-.716 2.08-1.567 2.08-.896 0-1.568-.951-1.568-2.08 0-1.13.717-2.08 1.568-2.08.85 0 1.567.95 1.567 2.08Z"
        fill="#8493A8"
        fillOpacity={0.1}
      />
      <path
        d="M55.11 65.918c0 1.188-.717 2.08-1.568 2.08-.896 0-1.567-.951-1.567-2.08 0-1.13.716-2.08 1.567-2.08s1.568.95 1.568 2.08Z"
        fill="#8493A8"
        fillOpacity={0.2}
      />
      <path
        d="M63.842 65.918c0 1.188-.716 2.08-1.567 2.08-.896 0-1.568-.951-1.568-2.08 0-1.13.717-2.08 1.568-2.08.85 0 1.567.95 1.567 2.08Z"
        fill="#8493A8"
        fillOpacity={0.1}
      />
      <path
        d="m46.613 121.422.4 1.248 5.645-.713s6.245 9.45 8.843 12.719c.25.357.55.713.9 1.189 1.648-4.755 4.996-19.733 1.598-20.921-5.046-1.724-17.386 6.478-17.386 6.478Z"
        fill="url(#b)"
      />
      <path
        d="M57.955 129.862c1.349 1.902 2.648 3.744 3.547 4.874.25.356.55.713.9 1.188 1.049-2.971 2.697-9.866 2.997-14.977-1.149.178-4.946 5.408-7.444 8.915Z"
        fill="url(#c)"
      />
      <path
        d="M52.66 122.017s6.244 9.45 8.842 12.719c2.598 3.209 8.494 12.778-7.394 12.065-15.937-.713-23.131-3.625-23.131-3.625l-.35-4.101 16.637-2.081s-10.392-1.426-9.143-13.135l14.538-1.842Z"
        fill="url(#d)"
      />
      <path
        d="m52.96 123.503.3-.595c1.598 2.437 6.144 9.213 8.243 11.828.999 1.248 2.548 3.507 3.047 5.706-1.199-3.388-4.946-6.538-8.143-10.223-4.097-4.755-3.447-6.716-3.447-6.716Z"
        fill="url(#e)"
      />
      <path
        d="M27.729 139.847h-.15c-.55.06-2.149.178-2.448 1.011-.35.951-.5 5.824 2.198 6.003 2.698.118-.5-2.378.4-3.448v-3.566Z"
        fill="#112B46"
      />
      <path
        d="m30.627 139.015 16.637-2.08s1.798.951 7.394 3.566c3.397 1.605 6.944 3.21 8.893 4.279-1.4 1.367-4.297 2.2-9.493 1.962-15.937-.713-23.131-3.626-23.131-3.626l-.3-4.101Z"
        fill="url(#f)"
      />
      <path
        d="M30.926 143.116s-1.998-1.01-3.098.357c-1.548 1.961-.1-3.626-.1-3.626l2.898-.832.3 4.101Z"
        fill="#FFE5C2"
      />
      <path
        d="M68.546 90.575 63.7 85.82a.617.617 0 0 1-.1-.653c.85-2.08.35-4.636-1.35-6.063-1.548-1.308-3.696-1.07-5.095.476-.25.297-.5.653-.7 1.01-.999 2.02-.649 4.636.9 6.181 1.449 1.427 3.547 1.427 4.996.179.05-.06.1-.06.15-.06.15-.06.3 0 .4.119l4.845 4.755c.15.119.3.178.45.178.15 0 .3-.119.4-.238.25-.475.2-.891-.05-1.129Zm-6.295-5.29c-1.05 1.486-2.898 1.724-4.147.476s-1.449-3.447-.4-4.933c1.05-1.486 2.898-1.724 4.147-.476 1.25 1.189 1.45 3.447.4 4.933Z"
        fill="#fff"
      />
      <path
        d="M61.851 80.293c-1.249-1.248-3.097-1.07-4.146.475-1.05 1.486-.9 3.685.4 4.933 1.248 1.249 3.097 1.07 4.146-.475 1.049-1.426.85-3.685-.4-4.933Z"
        fill="#8493A8"
        fillOpacity={0.1}
      />
      <path
        d="M65.949 87.722c-.45-.06-.9.357-1.35.832-.1-.475-.349-1.189-.549-1.307-.35-.179-.75 3.15-.75 3.15l.25-.238c-.05.06-.05.119-.05.119l2.349 1.426c1.548-2.436 1.199-3.804.1-3.982Z"
        fill="#FFE5C2"
      />
      <path
        d="M38.17 113.042c.45-5.766 1.15-11.947 2.05-13.611.449-.832.849-1.426 1.348-1.783 0 0-4.396 3.328-6.195 8.44-.85 2.555.8 5.052 2.798 6.954Z"
        fill="url(#g)"
      />
      <path
        d="m50.111 97.41-.15-2.258c.6-.12 1.15-.417 1.599-1.01 1.199-1.487 1.149-4.22.1-5.826-1 .357-2.248.12-2.298.773-.05 1.01-.15 1.843-.15 1.843-.8-.595-1.699-.535-1.549.772.05.535.35 1.367.7 1.427 0 0 .25 2.199-1.6 3.09v.773l3.348.416Z"
        fill="#FFE5C2"
      />
      <path
        d="M43.567 89.802c.799-3.922 2.098-6.18 5.645-6.24 3.247-.12 3.647 3.09 3.647 3.09-.1 2.734-3.397 1.427-3.447 2.497-.05 1.01-.15 1.842-.15 1.842-.8-.594-1.699-.535-1.549.773.05.535.35 1.367.7 1.426 0 0 .25 2.2-1.599 3.091-.05-.06-4.047-2.556-3.247-6.479Z"
        fill="url(#h)"
      />
      <path
        d="M43.567 89.803c.799-3.923 2.098-6.181 5.645-6.24 2.148-.06 3.048 1.307 3.397 2.258-1.548-1.783-4.996-2.853-7.144 1.248-1.848 3.566-.7 7.846 2.748 7.37-.2.654-.6 1.367-1.449 1.783 0 0-3.997-2.496-3.197-6.419Z"
        fill="url(#i)"
      />
      <path
        d="M49.212 95.865c-.3-.238-.65-1.367-.65-1.367.35.475 1.4.654 1.4.654l.15 2.258-.75-.118c0-.654 0-1.308-.15-1.427Z"
        fill="#FED2A4"
      />
      <path
        d="M37.422 124.275s1-21.516 2.798-24.844c.749-1.367 1.398-2.08 2.548-2.377.699-.179 3.297-.298 3.996-.179 1.55.179 5.496.773 6.445 1.249 1.3.653 6.545 6.062 6.545 6.062l3.497-14.265 2.898 1.308s-2.848 20.862-5.096 20.862c-2.248.059-8.044-3.804-8.044-3.804s-.45 13.076 1.7 15.512c.049.06-8.994 4.993-17.287.476Z"
        fill="url(#j)"
      />
      <path
        d="M40.22 98.837c.799-.773 1.349-1.19 1.349-1.19-.5.417-.9.952-1.35 1.784-.899 1.664-1.598 7.845-2.048 13.611-.5-.476-.95-1.011-1.349-1.546.2-3.387.9-9.45 3.398-12.66Z"
        fill="#147AC6"
      />
      <path
        d="M49.811 106.742c-.3-.713 3.247 1.605 3.247 1.605s-.45 13.075 1.699 15.512c0 0-.65.357-1.699.773-1.249-4.398-.949-10.283-1.199-12.363-.3-2.853-1.748-4.814-2.048-5.527Z"
        fill="url(#k)"
      />
      <path
        d="M176.462 144.246c0 .178 0 .416-.05.653-.05.714-.7 1.13-.85 1.249.1 0 .15 0 .2.059 1.099.535 1.699.713 2.748.178.1-.059.15-.059.25 0-.05-.594-.15-1.486-.2-2.139h-2.098Z"
        fill="#FFE5C2"
      />
      <path
        d="M178.96 148.347v-1.546c0-.297-.25-.475-.45-.356-1.049.535-1.648.416-2.747-.178-.85-.417-3.498 1.367-4.147 1.842-.35.297-.15.892.25.892h6.544c.3 0 .55-.298.55-.654Z"
        fill="#112B46"
      />
      <path
        d="M187.255 143.77c0 .178 0 .416-.05.653-.05.714-.7 1.13-.85 1.248.1 0 .15 0 .2.06 1.099.535 1.699.713 2.748.178.1-.059.15-.059.25 0-.05-.594-.15-1.486-.2-2.139h-2.098Z"
        fill="#FFE5C2"
      />
      <path
        d="M189.802 147.871v-1.546c0-.297-.25-.475-.45-.356-1.049.535-1.649.416-2.748-.179-.849-.416-3.497 1.367-4.146 1.843-.35.297-.15.891.249.891h6.545c.25 0 .5-.297.55-.653Z"
        fill="#112B46"
      />
      <path
        d="M187.752 98.955c0 1.07.65 4.042.65 7.37 0 6.36-2.698 14.859-2.698 14.859l4.546 22.942-3.847.119-8.493-22.407.6-24.012 9.242 1.13Z"
        fill="url(#l)"
      />
      <path
        d="M184.705 97.707s-.599 7.489-1.898 12.957c-1.049 4.577-8.793 11.59-8.793 11.59l5.046 22.288h-3.148l-11.241-21.693a4.303 4.303 0 0 1 .1-2.853l10.742-24.25 9.192 1.962Z"
        fill="url(#m)"
      />
      <path
        d="M158.226 93.666h-4.046s.2 2.853 4.396 2.853l-.35-2.853ZM179.909 69.357c.05-.238.1-.892.05-1.248-.1-1.486-.849-1.724-1.748-.832 0 0-.8-.595-1.199-1.724-.25-.654-1.349-.178-2.149-.357-.699 2.08-.05 4.993 1.449 6.3.6.535 1.199.595 1.949.595l.2 3.685 3.797-1.189-.05-2.199c-1.499.119-2.548-1.367-2.299-3.031Z"
        fill="#FFE5C2"
      />
      <path
        d="M176.962 144.542c-.799-1.604-10.641-21.575-9.192-24.012 1.499-2.555 8.243-18.306 8.243-18.306l-1.599-2.436s-.499.475-1.249 1.248l-8.343 18.96c-.35.891-.4 1.902-.1 2.852l11.241 21.694h.999Z"
        fill="url(#n)"
      />
      <path
        d="M175.663 79.758c.2-4.577 6.195-4.933 6.795-4.933.849 0 1.648.535 2.198 1.248.949 1.248 2.798 4.041 2.198 7.905-1.499 9.985 1.599 16.404 1.999 19.494-5.546 2.675-17.287-.653-17.287-.653l3.598-16.226.499-6.835Z"
        fill="url(#o)"
      />
      <path
        d="M171.216 90.635s3.647-15.75 8.294-14.86c5.145 1.011-3.348 20.565-3.348 20.565l-17.586.416-.349-3.328 12.989-2.793Z"
        fill="url(#p)"
      />
      <path
        d="M178.81 95.865c-.85 3.09-3.747 2.853-3.747 2.853l-1.599 4.695c-1.199-.297-1.948-.475-1.948-.475l1.399-6.42 3.197-.059s5.895-13.61 4.896-18.722c.2.357 1.649 4.458-2.198 18.128Z"
        fill="url(#q)"
      />
      <path
        d="M183.806 85.345c-5.945-.357-3.947-14.146-3.897-16.82.05-1.486-.849-2.14-1.699-1.248 0 0-.799-.595-1.199-1.724-.249-.713-1.598-.178-2.398-.357 1.699-8.142 9.743-7.786 13.29 3.27 3.047 9.509 5.395 17.473-4.097 16.879Z"
        fill="url(#r)"
      />
      <path
        d="M178.759 72.863c.15-.178.8-1.664.8-1.664-.3.357-.9.713-1.149.832-.1.06-.15.06-.15.06l.2 3.685.399-.12c-.1-1.069-.2-2.674-.1-2.793Z"
        fill="#FED2A4"
      />
      <path
        d="M174.514 83.621c.799-4.339 3.197-7.073 3.797-7.727-4.097 1.665-7.095 14.74-7.095 14.74l-12.989 2.794 14.538-1.01s.849-3.804 1.749-8.797Z"
        fill="url(#s)"
      />
      <path
        d="M181.108 61.63c4.346 2.318 6.794 10.699 5.645 23.537 6.046-1.189 3.897-8.321 1.199-16.702-3.547-11.055-11.59-11.411-13.289-3.269-.05 0 2.098-5.884 6.445-3.566Z"
        fill="url(#t)"
      />
      <defs>
        <linearGradient
          id="a"
          x1={108.721}
          y1={0.289}
          x2={108.721}
          y2={148.38}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DCE9FC" />
          <stop offset={1} stopColor="#DCE9FC" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="b"
          x1={46.626}
          y1={125.295}
          x2={65.482}
          y2={125.295}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="c"
          x1={57.96}
          y1={128.443}
          x2={65.406}
          y2={128.443}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="d"
          x1={30.651}
          y1={134.44}
          x2={64.778}
          y2={134.44}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="e"
          x1={52.93}
          y1={131.683}
          x2={64.56}
          y2={131.683}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="f"
          x1={30.65}
          y1={141.863}
          x2={63.579}
          y2={141.863}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="g"
          x1={34.844}
          y1={99.995}
          x2={42.267}
          y2={111.927}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="h"
          x1={48.175}
          y1={83.59}
          x2={48.175}
          y2={96.226}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="i"
          x1={48.05}
          y1={83.591}
          x2={48.05}
          y2={96.167}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="j"
          x1={36.079}
          y1={95.45}
          x2={47.611}
          y2={130.65}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="k"
          x1={49.561}
          y1={109.354}
          x2={59.234}
          y2={119.616}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="l"
          x1={177.919}
          y1={121.058}
          x2={190.261}
          y2={121.058}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="m"
          x1={164.489}
          y1={120.167}
          x2={184.723}
          y2={120.167}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="n"
          x1={164.534}
          y1={122.186}
          x2={176.973}
          y2={122.186}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="o"
          x1={170.758}
          y1={79.352}
          x2={182.628}
          y2={105.976}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="p"
          x1={157.154}
          y1={78.944}
          x2={162.24}
          y2={100.329}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="q"
          x1={171.052}
          y1={81.653}
          x2={183.827}
          y2={100.676}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="r"
          x1={182.622}
          y1={59.63}
          x2={182.622}
          y2={85.264}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="s"
          x1={157.288}
          y1={78.568}
          x2={161.351}
          y2={96.497}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="t"
          x1={182.677}
          y1={59.63}
          x2={182.677}
          y2={85.055}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default EmptySearchResult;
