import React from 'react';
import Svg from '@shared/svg/Pages';
import EmptyState from './EmptyState';
import type { EmptyStateProps } from './EmptyState';

type PagesEmptyStateProps = Omit<EmptyStateProps, 'image' | 'className'>;

const PagesEmptyState: React.FC<PagesEmptyStateProps> = (props) => (
  <EmptyState image={<Svg />} {...props} />
);

export default PagesEmptyState;
