import React from 'react';
import ErrorBoundaryToast from '@lobox/uikit/ErrorBoundary/ErrorBoundaryToast';
import CollectionModalFormComponent from './CollectionModalForm';
import type { CollectionModalFormProps } from './CollectionModalForm';

const CollectionModalForm = (props: CollectionModalFormProps): JSX.Element => (
  <ErrorBoundaryToast>
    <CollectionModalFormComponent {...props} />
  </ErrorBoundaryToast>
);

export default CollectionModalForm;
