import classes from './UnitSectionSkeleton.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import { SKELETON_NUMBERS } from '@shared/constants/enums';
import ObjectCardSkeleton from '../ObjectCard/ObjectCard.skeleton';

export interface UnitSectionSkeletonProps {
  className?: string;
  totalElements?: number;
  isPage?: boolean;
  myOwnPage?: boolean;
}

const UnitSectionSkeleton: React.FC<UnitSectionSkeletonProps> = ({
  className,
  totalElements = SKELETON_NUMBERS,
  isPage,
  myOwnPage,
}) => {
  const list = Array(totalElements)
    .fill(0)
    .map((_, i) => i);

  return (
    <Flex className={cnj(classes.unitSectionSkeletonRoot, className)}>
      {list.map((i) => (
        <Flex key={`${i}`} className={classes.item}>
          <ObjectCardSkeleton
            key={`obj_sk_${i}_${isPage}_${myOwnPage}`}
            isPage={isPage}
            myOwnPage={myOwnPage}
          />
        </Flex>
      ))}
    </Flex>
  );
};

export default UnitSectionSkeleton;
